/**
 * 模块名称: 清理发票详情
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import { upLoadModule } from '@/utils/common'
import {
  Table,
	Descriptions,
  Button,
  Input,
  Icon,
  message,
  Upload
} from 'antd'

const dItem = Descriptions.item
const { Column } = Table
let rechargenum = []

const Detail = (props) => {
  const { location, history } = props
  const [loading, setLoading] = useState(false)
  const [propsData, setPropsData] = useState({})
  const [detail, setDetail] = useState({})
  const [claiMList, setClaiMList] = useState([])
  const [fortuneUnInvoiceList, setFortuneUnInvoiceList] = useState([])
  const [remark, setRemarkVal] = useState('')
  const [buslicense, setBuslicense] = useState([])
  const [certificate, setCertificate] = useState([])
  const [invnprove, setInvnprove] = useState([])
  const [invcontract, setInvcontract] = useState([])
  const [invothers, setInvothers] = useState([])
  const [acountCheckedTotal, setAcountCheckedTotal] = useState(0)

  const [fileList, setFileList] = useState([])

  const draggerRef = useRef(null)

  useEffect(() => {
    // console.log('location', location)
    if(!location.state.unInvoiceData){
      // history.replace('/protocol/invoice')
      history.goBack()
      return
    }
    setPropsData(location.state.unInvoiceData)
  }, [])

  useEffect(() => {
    rechargenum = []
    getfortuneInvoiceDetail()
    getFortuneUnInvoice()
  }, [])

  //获取发票详情
  const getfortuneInvoiceDetail = () => {
    api.getfortuneInvoiceDetail({
      id: location.state.unInvoiceData.id,
      type: 0
    }).then(res => {
      getColleway(res.mainId)
      setDetail(res)
      setRemarkVal(res.remark)
      setBuslicense(res.buslicense)
      setCertificate(res.certificate)
      setInvnprove(res.invnprove)
      setInvcontract(res.invcontract)
      setInvothers(res.invothers)
    })
  }

  //获取未收款发票列表
  const getFortuneUnInvoice = () => {
    api.getFortuneUnInvoice({
      OAID: location.state.unInvoiceData.customerId,
      type: location.state.type
    }).then(data => {
      setFortuneUnInvoiceList(data.list)
    })
  }

  //获取收款途径
  const getColleway = (mainId) => {
    // console.log(e)
    api.getFortuneInvoiceClaim({
      OAID: location.state.unInvoiceData.customerId,
      accountId: mainId,
      leadId: location.state.creatorId,
      channel: 1
    }).then(data => {
      setClaiMList(data.list)
    }).catch(res => {})
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setAcountCheckedTotal(0)
      let total = 0
      rechargenum = []
      if(selectedRows.length){
        for(let i =0; i<selectedRows.length; i++){
          total = selectedRows[i].balance - 0 + total
          // console.log(selectedRows[i])
          rechargenum.push(selectedRows[i].id)//
        }
        setAcountCheckedTotal(total)
      }
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    })
  }
  
  // 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png',
		allowSizeType: 4
	})

  const getRemark = e => {
    e.persist()
    // console.log(e.target.value)
    setRemarkVal(e.target.value)
  }

  const onSubmit = () => {
    // if(!fileList.length > 0) {
    //   return message.warning('请上传资质！')
    // }
    if(rechargenum == '') {
      return message.warning('未选中发票余额！')
    }
    let params = {
      remark,
      id: propsData.id,
      selectBalance: acountCheckedTotal,
      fundId: rechargenum.join(','),
      qualification: fileList
    }
    // console.log('params=>', params)
    setLoading(true)
    api.onfortuneInvoiceClear(params).then(res => {
      message.success('提交清理成功')
      history.replace('/protocol/invoice')
      setLoading(false)
    }).catch(err => setLoading(false))
  }

  return (
    <div className="add-newinvoice-detail">
      <div className="ask-info">
        <div className="record-list-title">发票清理</div>
        <div>发票业务编号：{propsData.invoiceNo}</div>
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">客户信息</div>
        <Descriptions layout="vertical" column={4} className="pro-detail-title" style={{borderBottom: 'none'}}>
          <dItem label="OAID">{detail.customerId}</dItem>
          <dItem label="客户名称">{detail.customerName}</dItem>
          <dItem label="创建人">{detail.applyName}</dItem>
          <dItem label="开票账户">{detail.companyName}</dItem>
          <dItem label="发票类型">{detail.invTypeName}</dItem>
          <dItem label="发票号">{detail.invoiceNumber}</dItem>
          {
            detail.invTypeName == "电子普票" || detail.invTypeName == "电子专票" ?
              <dItem label="发票附件">
                  {
                      detail.invoiceUrl && detail.invoiceUrl.length > 0 ? detail.invoiceUrl.map((item, i) => {
                          return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                      }) : ''
                  }
              </dItem> : null
          }
        </Descriptions>
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">发票信息</div>
        <Descriptions layout="vertical" column={4} className="pro-detail-title" style={{borderBottom: 'none'}}>
          <dItem label="发票抬头">{detail.invtitle}</dItem>
          <dItem label="开票项目(服务费或广告费)">{detail.project}</dItem>
          <dItem label="纳税人识别号">{detail.invident}</dItem>
          <dItem label="电话">{detail.invtel}</dItem>
          <dItem label="地址">{detail.address}</dItem>
          <dItem label="开户银行">{detail.invkhbank}</dItem>
          <dItem label="开户银行账号">{detail.invaccount}</dItem>
        </Descriptions>
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">附件信息</div>
        <div className="record-title-tip">支持扩展名：.rar .zip .doc .docx .pdf .png</div>
        <Descriptions layout="vertical" column={4} className="pro-detail-title" style={{borderBottom: 'none'}}>
            {[[buslicense, '营业执照'], [certificate, '税务登记证'], [invnprove, '一般纳税人证明'], [invcontract, '合同'], [invothers, '其他']].map((data, index) => {
                return <dItem label={data[1]} key={index}>
                    <div className="content">
                        {
                            data[0] !== null && data[0].length ? data[0].map((item, index2) => {
                                return <div key={index2}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                            }) : '无'
                        }
                    </div>
                </dItem>
            })}
            <dItem label="上传资质">
              <div className="dragger-box" ref={draggerRef}>
                <Upload
                  {...uploadFiles}
                >
                  <Button>
                    <Icon type="upload" /> 上传文件
                  </Button>
                </Upload>
              </div>
            </dItem>
        </Descriptions>
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">收款信息</div>
        <Descriptions layout="vertical" column={4} className="pro-detail-title" style={{borderBottom: 'none'}}>
          <dItem label="收款状态">{detail.colleStatusName}</dItem>
          <dItem label="收款途径">公司收款</dItem>
          <dItem label="开票金额">{detail.money}</dItem>
          <dItem label="待清理金额">{detail.clearedMoney}</dItem>
        </Descriptions>
        <Table
          dataSource={claiMList}
          rowKey="rId"
          className="today-info"
          pagination={false}
          rowSelection={rowSelection}
        >
          <Column title="客户名称" dataIndex="customerName" />
          <Column title="OAID" dataIndex="OAID" />
          <Column title="账户" dataIndex="accountName" />
          <Column title="类型" dataIndex="type" />
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="付款方户名" dataIndex="payName" />
          {/* <Column title="业务ID" dataIndex="rId" /> */}
          <Column title="收款" dataIndex="collect" />
          <Column title="发票额度支出" dataIndex="pay" />
          <Column title="发票余额" dataIndex="balance" />
          <Column title="备注" dataIndex="remark" />
          <Column title="负责人" dataIndex="operateName" />
          <Column title="操作时间" dataIndex="operateTime" />
        </Table>
        {<div className="acount-checked-total">
          已选中发票余额 <span style={{color: 'red'}}>{acountCheckedTotal}</span>
        </div>}
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">其他信息</div>
        <div className="record-list-title" style={{borderBottom: 'none', fontSize: '16px'}}>未收款发票：</div>
        <Table
          dataSource={fortuneUnInvoiceList}
          rowKey="id"  
          className="fortuneUnInvoiceList-info"
          pagination={false}
        >
          <Column title="序号" dataIndex="id" />
          <Column title="业务编号" dataIndex="invoiceNo" />
          <Column title="客户名称" dataIndex="customerName" />
          <Column title="金额" dataIndex="money" />
          <Column title="发票类型" dataIndex="invtype" />
          <Column title="发票号" dataIndex="invoiceNumber" />
          <Column title="申请人" dataIndex="applyName"/>
          <Column title="发票状态" dataIndex="stcodetag" />
        </Table>
        <div className="record-list-title" style={{borderBottom: 'none', fontSize: '16px', marginTop: '30px', marginBottom: 0}}>备注：</div>
        <div>
          <Input.TextArea placeholder="请输入" maxLength={100} autosize={{minRows: 3, maxRows: 6}} onChange={getRemark}>{remark}</Input.TextArea>
        </div>
      </div>
      <div className="blank-line"></div>
      <div className="task-bottom-btns">
        <Button className="operate-icon" size="default" onClick={() => {
          history.replace('/protocol/invoice')
        }}>取消</Button>
        <Button className="operate-icon" type="primary" size="default" onClick={onSubmit} loading={loading}>提交</Button>
      </div>
    </div>
  )
}
export default Detail