/**
 * 模块名称: 发票详情 - 公共部分
 * @author yue
 */
import React, { useEffect, useState } from 'react'
import api from '@/api'
import { Descriptions, Form, Input, Steps, Table, message, Button } from 'antd'
import { auditRecordRender } from "../../AgentTransfer/module/AgentTransferDetail";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { onUrge } from '@/utils'

const FormItem = Form.Item
const dItem = Descriptions.item
const { Column } = Table
const { Step } = Steps

const PublicDetail = (props) => {
	// const {location, history} = props
	const params = props.params
	const pageType = props.type
	const { setFieldsValue, getFieldDecorator, validateFields } = props.form
	const [detail, setDetail] = useState({})
	const [fortuneUnInvoiceList, setFortuneUnInvoiceList] = useState([])
	const [auditRecord, setAuditRecord] = useState([])
	const [auditStatusName, setAuditStatusName] = useState(undefined)

	const [flowCurrent, setFlowCurrent] = useState({})
	const [flow, setFlow] = useState([])
	const [remark, setRemark] = useState([])
	const [affixList, setAffixList] = useState([])
	const [buslicense, setBuslicense] = useState([])
	const [certificate, setCertificate] = useState([])
	const [invnprove, setInvnprove] = useState([])
	const [invcontract, setInvcontract] = useState([])
	const [invothers, setInvothers] = useState([])

	useEffect(() => {
		if (params !== undefined && params.length != 0) {
			getFortuneInvoiceDetail();
		}
	}, [params])

	const getDetail = props.getDetail

	//获取发票详情
	const getFortuneInvoiceDetail = () => {
		api.getfortuneInvoiceDetail({
			id: params.id,
			type: 0
		}).then(res => {
			setDetail(res);

			if (getDetail !== undefined) {
				getDetail(res);
			}

			if (pageType == 'modify') {
				setFieldsValue({
					invtitle: res.invtitle,
					project: res.project,
					invident: res.invident,
					address: res.address,
					invtel: res.invtel,
					invkhbank: res.invkhbank,
					invaccount: res.invaccount,
				})
			}

			setBuslicense(res.buslicense);
			setCertificate(res.certificate);
			setInvnprove(res.invnprove);
			setInvcontract(res.invcontract);
			setInvothers(res.invothers);

			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				if (data.status === 0) data.current = 0
				if (data.status === 1) data.current = 1
				if (data.status === 2) data.current = 2
				if (data.status === 3) data.current = 3
				setFlowCurrent(data)

				let auditFlow = data.audit_flow
				auditFlow.forEach((item, index, self) => {
					if (item.persons && item.persons.length) {
						self[index].persons = item.persons.join('，')
					}
				})

				setFlow(auditFlow)

				// setAffixList(res.qualification)
				setAuditRecord(data.audit_log)
				setAuditStatusName(data.auditStatusName)
				if (data.remark.length) {
					setRemark(data.remark)
				} else {
					setRemark('无')
				}
			} else {
				setDetail({})
				setFlow([])
				// setAffixList([])
				setAuditRecord([])
			}
			getFortuneUnInvoice(res.customerId, res.clientsouce);
		})
	}

	//获取未收款发票列表
	const getFortuneUnInvoice = (oaId, type) => {
		api.getFortuneUnInvoice({
			OAID: oaId,
			type: type
		}).then(data => {
			setFortuneUnInvoiceList(data.list)
		})
	}

	//获取尾部信息
	function getEndInfo(detail) {
		if (pageType == 'detail' && Object.keys(detail).length != 0) {
			if (detail.redType) {
				return chongHongDetail(detail)
			} else {
				return returnAndModify(detail)
			}
		}
	}

	//冲红类型的发票冲红信息
	const chongHongDetail = (detail) => {
		return (<>
			<div className="blank-line"></div>
			<div className="ask-info">
				<div className="record-list-title">当前冲红信息</div>
				<Descriptions layout="horizontal" className="pro-detail-smailtitle">
					<dItem label="冲红发票号">{detail.chonghong ? detail.chonghong.invnumber : undefined}</dItem>
					<dItem label="冲红金额">{detail.chonghong ? detail.chonghong.amount : undefined}</dItem>
				</Descriptions>
				<Descriptions title="资质" layout="horizontal" className="pro-detail-smailtitle">
					<div className="content">
						{
							(detail.qualification != null && detail.qualification.length) ? detail.qualification.map((item, index) => {
								return <div key={index}>
									<a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a>
								</div>
							}) : '无'
						}
					</div>
				</Descriptions>
				<Descriptions title="冲红说明" layout="horizontal" className="pro-detail-smailtitle">
					<div className="content">
						{detail.chonghong.explain ? detail.chonghong.explain : '无'}
					</div>
				</Descriptions>
			</div>
			{chonghongMul(detail)}
		</>)
	}

	
	//退票类型的发票退票信息
	const returnDetail = (detail) => {
		return (<>
			<div className="blank-line"></div>
			<div className="ask-info">
				<div className="record-list-title">当前退票信息</div>
				<Descriptions layout="horizontal" className="pro-detail-smailtitle">
					<dItem label="退票发票号">{detail.return ? detail.return.invnumber : undefined}</dItem>
					<dItem label="退票金额">{detail.return ? detail.return.amount : undefined}</dItem>
				</Descriptions>
				<Descriptions title="退票说明" layout="horizontal" className="pro-detail-smailtitle">
					<div className="content">
						{detail.return.explain ? detail.return.explain : '无'}
					</div>
				</Descriptions>
			</div>
		</>)
	}

	//历史退票列表
	const returnMul = (detail) => {
		return (<>
			{detail.returnMul.length > 0 && <><div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">历史退票信息</div>
					{
						detail.returnMul.map((item, index) => {
							return <div key={index}>
								<Descriptions layout="vertical" className="pro-detail-title" column={5} style={{ borderBottom: "none", marginBottom: "-30px" }}>
									<dItem label="退票金额"></dItem>
									<dItem label="发票号"></dItem>
									<dItem label="申请时间"></dItem>
									<dItem label="申请人"></dItem>
									<dItem label="审批状态"></dItem>
								</Descriptions>
								<Descriptions layout="horizontal" className="pro-detail-title" column={5} style={{ borderBottom: "none", marginBottom: "-10px" }}>
									<dItem label="">{item.amount}</dItem>
									<dItem label="">{item.invnumber}</dItem>
									<dItem label="">{item.addTime}</dItem>
									<dItem label="">{item.addStaffName}</dItem>
									<dItem label="">{item.statusName}</dItem>
								</Descriptions>
							</div>
						})
					}
				</div></>}
		</>)
	}

	//历史冲红列表
	const chonghongMul = (detail) => {
		return (<>
			{detail.chonghongMul.length > 0 && <><div className="blank-line"></div>
				<div className="ask-info">
					<div className="record-list-title">历史冲红信息</div>
					{
						detail.chonghongMul.map((item, index) => {
							return <div key={index}>
								<Descriptions layout="vertical" className="pro-detail-title" column={6} style={{ borderBottom: "none", marginBottom: "-30px" }}>
									<dItem label="冲红金额"></dItem>
									<dItem label="冲红发票号"></dItem>
									<dItem label="申请时间"></dItem>
									<dItem label="申请人"></dItem>
									<dItem label="发票号"></dItem>
									<dItem label="审批状态"></dItem>
								</Descriptions>

								<Descriptions layout="horizontal" className="pro-detail-title" column={6} style={{ borderBottom: "none", marginBottom: "-10px" }}>
									<dItem label="">{item.amount}</dItem>
									<dItem label="">{item.invnumber}</dItem>
									<dItem label="">{item.addTime}</dItem>
									<dItem label="">{item.addStaffName}</dItem>
									<dItem label="">{detail.invoiceNumber}</dItem>
									<dItem label="">{item.statusName}</dItem>
								</Descriptions>
							</div>
						})
					}
				</div></>}
		</>)
	}

	//原发票的退票和冲红信息
	const returnAndModify = (detail) => {
		return (<>
			{returnMul(detail)}
      {detail.operateType !== 2 && chonghongMul(detail)}
		</>)
	}

  const onCopyEmail = (copyVal) => {
    return <>
      发票收件人电子邮箱：
      {
        copyVal !== '' &&
        <CopyToClipboard
          onCopy={() => message.success('复制成功')}
          text={copyVal}
        >
          <Button
            type="primary"
            size="small"
            style={{ backgroundColor: '#d7d7d7', borderColor: '#d7d7d7', color: '#000', fontSize: '12px' }}>
            复制
          </Button>
        </CopyToClipboard>
      }
    </>
  }

	return (
		<div className="add-newinvoice-detail">
			<div className="ask-info">
				<div className="record-list-title">{pageType == 'modify' ? '发票冲红' : '发票详情'}</div>
				<div>发票业务编号：{detail.invoiceNo}</div>
			</div>
			{pageType == 'detail' && <div>
				<div className="blank-line"></div>
				<div className="step-wrap">
					<div className="record-list-title">当前审批进度&nbsp;
						{detail.auditAuth && detail.auditAuth.urge && <Button type='primary' size="small" onClick={() => onUrge('urgeInvoice', detail.auditAuth.businessNo)}>催一催</Button>}
					</div>
					<Steps current={flowCurrent.current}>
						{
							flow.map((item, index, self) => {
								let status = 'wait'
								if (item.time && item.time.length > 1) {
									status = 'process'
								}
								return <Step status={status} title={item.nodeName} key={index} description={
									<>
										{item.persons && <div title={item.persons}>{item.persons}</div>}
										<div title={item.statusName}>{item.statusName}</div>
										<div title={item.time}>{item.time}</div>
									</>
								} />
							})
						}
					</Steps>
				</div>
			</div>}
			<div className="blank-line"></div>
			<div className="ask-info">
				<div className="record-list-title">客户信息</div>
				<Descriptions layout="vertical" column={4} className="pro-detail-title" style={{ borderBottom: 'none' }}>
					<dItem label={detail.clientsouce == 0 ? 'OAID' : 'QID'}>{detail.customerId}</dItem>
					<dItem label={detail.clientsouce == 0 ? '客户名称' : '代理商名称'}>{detail.customerName}</dItem>
					<dItem label="申请人">{detail.applyName}</dItem>
					<dItem label="开票账户">{detail.companyName}</dItem>
					<dItem label="发票类型">{detail.invTypeName}</dItem>
					<dItem label="发票号">{detail.invoiceNumber}</dItem>
					{
						detail.invTypeName == "电子普票" || detail.invTypeName == "电子专票" ?
							<dItem label="发票附件">
								{
									detail.invoiceUrl && detail.invoiceUrl.length > 0 ? detail.invoiceUrl.map((item, i) => {
										return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
									}) : ''
								}
							</dItem> : null
					}
				</Descriptions>
			</div>
			<div className="blank-line"></div>
			{pageType == 'modify' ?
				<div className="ask-info">
					<div className="record-list-title">发票信息</div>
					<FormItem label="发票抬头">
						{getFieldDecorator('invtitle', {
							rules: [{ required: true, message: '请输入发票抬头' }]
						})
							(<Input placeholder="请输入" style={{ width: 180 }} />)}
					</FormItem>
					<FormItem label="开票项目（服务费或技术服务费）">
						{getFieldDecorator('project', {
							rules: [{ required: true, message: '请输入开票项目' }]
						})
							(<Input placeholder="请输入" style={{ width: 180 }} />)}
					</FormItem>
					<FormItem label="纳税人识别号">
						{getFieldDecorator('invident', {
							rules: [{ required: true, message: '请输入纳税人识别号' }]
						})
							(<Input placeholder="请输入" style={{ width: 180 }} />)}
					</FormItem>
					<FormItem label="地址">
						{getFieldDecorator('address', {
							rules: [{ required: detail.invtype === 2 ? true : false, message: '请输入地址' }]
						})
							(<Input placeholder="请输入" style={{ width: 180 }} />)}
					</FormItem>
					<FormItem label="电话">
						{getFieldDecorator('invtel', {
							rules: [{ required: detail.invtype === 2 ? true : false, message: '请输入电话' }]
						})
							(<Input placeholder="请输入" style={{ width: 180 }} />)}
					</FormItem>
					<FormItem label="开户银行">
						{getFieldDecorator('invkhbank', {
							rules: [{ required: detail.invtype === 2 ? true : false, message: '请输入开户银行' }]
						})
							(<Input placeholder="请输入" style={{ width: 180 }} />)}
					</FormItem>
					<FormItem label="开户银行账号">
						{getFieldDecorator('invaccount', {
							rules: [{ required: detail.invtype === 2 ? true : false, message: '请输入开户银行账号' }]
						})
							(<Input placeholder="请输入" style={{ width: 180 }} />)}
					</FormItem>
				</div>
				:
				<div className="ask-info">
					<div className="record-list-title">发票信息</div>
					<Descriptions layout="vertical" column={4} colon={false} className="pro-detail-title" style={{ borderBottom: 'none' }}>
						<dItem label="发票抬头：">{detail.invtitle}</dItem>
						<dItem label="开票项目(服务费或广告费)：">{detail.project}</dItem>
						<dItem label="纳税人识别号：">{detail.invident}</dItem>
						<dItem label="电话：">{detail.invtel}</dItem>
						<dItem label="地址：">{detail.address}</dItem>
						<dItem label="开户银行：">{detail.invkhbank}</dItem>
						<dItem label="开户银行账号：">{detail.invaccount}</dItem>
						{
							detail.invTypeName == "电子普票" || detail.invTypeName == "电子专票" ?
								<dItem label={onCopyEmail(detail.email)}>{detail.email}</dItem> : null
						}
					</Descriptions>
				</div>
			}
			<div className="blank-line"></div>
			<div className="ask-info">
				<div className="record-list-title">附件信息</div>
				<Descriptions layout="vertical" column={4} className="pro-detail-title" style={{ borderBottom: 'none' }}>
					{[[buslicense, '营业执照'], [certificate, '税务登记证'], [invnprove, '一般纳税人证明'], [invcontract, '合同'], [invothers, '其他']].map((data, index) => {
						return <dItem label={data[1]} key={index}>
							<div className="content">
								{
									data[0] !== null && data[0].length ? data[0].map((item, index2) => {
										return <div key={index2}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
									}) : '无'
								}
							</div>
						</dItem>
					})}
				</Descriptions>
			</div>
			<div className="blank-line"></div>
			<div className="ask-info">
				<div className="record-list-title">收款/到款信息</div>
				<Descriptions layout="vertical" column={4} className="pro-detail-title" style={{ borderBottom: 'none' }}>
					<dItem label="收款状态">{detail.colleStatusName}</dItem>
					{detail.colleway && <dItem label="收款途径">{detail.colleWayName}</dItem>}
					<dItem label="开票金额">{detail.money}</dItem>
					<dItem label="待清理金额">{detail.clearedMoney}</dItem>
				</Descriptions>
				{(detail.collestatus == 2 || (detail.operateType == 3)) && <Table
					dataSource={detail.RenKuan && detail.RenKuan.list}
					rowKey="id"
					className="today-info"
					expandedRowRender={(record) => {
						if (record.DaoKuan.length === 0) {
							return null
						} else {
							return <Table
								rowKey="id"
								dataSource={record.DaoKuan}
								pagination={false}
							>
								<Column title="到款方式" dataIndex="payWay" />
								<Column title="到款银行" dataIndex="bank" />
								<Column title="到款日期" dataIndex="date" />
								<Column title="付款方户名" dataIndex="name" />
								<Column title="付款方账号" dataIndex="account" />
								<Column title="到款金额" dataIndex="amount" />
								<Column title="认款金额" dataIndex="rkQuota" />
								<Column title="可认余额" dataIndex="balance" />
								<Column title="到款备注" dataIndex="note" />
							</Table>
						}
					}}
					pagination={false}
				>
					<Column title="客户名称/代理商名称" dataIndex="client" />
					<Column title="OAID/QID" dataIndex="clientId" />
					<Column title="账户" dataIndex="companyName" />
					<Column title="类型" dataIndex="accountType" />
					<Column title="业务编号" dataIndex="businessNo" />
					<Column title="付款方户名" dataIndex="bankName" />
					{/* <Column title="业务ID" dataIndex="rId"/> */}
					<Column title="收款" dataIndex="collectMoney" />
					<Column title="发票额度支出" dataIndex="pay" />
					<Column title="发票余额" dataIndex="balance" />
					<Column title="本次开票扣减" dataIndex="invoiceChangeMoney" />
					<Column title="备注" dataIndex="remark" />
					<Column title="负责人" dataIndex="userName" />
					<Column title="审批通过时间" dataIndex="operateTime" />
				</Table>}
			</div>
			<div className="blank-line"></div>
			<div className="ask-info">
				<div className="record-list-title">其他信息</div>
				<div className="record-list-title" style={{ borderBottom: 'none', fontSize: '16px' }}>未收款发票：</div>
				<Table
					dataSource={fortuneUnInvoiceList}
					rowKey="id"
					className="fortuneUnInvoiceList-info"
					pagination={false}
				>
					<Column title="序号" dataIndex="id" />
					<Column title="业务编号" dataIndex="invoiceNo" />
					<Column title="客户名称" dataIndex="customerName" />
					<Column title="金额" dataIndex="money" />
					<Column title="发票类型" dataIndex="invtype" />
					<Column title="发票号" dataIndex="invoiceNumber" />
					<Column title="申请人" dataIndex="applyName" />
					<Column title="发票状态" dataIndex="stcodetag" />
				</Table>
			</div>
			<div className="blank-line"></div>
			<div className="ask-info">
				<div className="record-list-title">备注</div>
				<Descriptions title="" layout="horizontal" className="pro-detail-smailtitle">
					<div className="content">
						{remark}
					</div>
				</Descriptions>
			</div>
			{detail.operateType === 2 && returnDetail(detail)}
			{getEndInfo(detail)}
			{(params.editId || pageType == 'detail') && auditRecordRender(auditRecord, auditStatusName)}
		</div>
	)
}
export default PublicDetail