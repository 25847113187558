/**
 * 模块名称: 申请收据
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Select,
  Input,
  Form,
  message,
  Row,
  Col,
  Upload,
  Icon,
  Table
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
// import moment from 'moment'

const { Option } = Select
const { Column } = Table
const { Dragger } = Upload
const FormItem = Form.Item

let pageSize = 10
let currentPage = 1
let rechargenum = [];
const Apply = (props) => {

  const { match, location, history } = props
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldValue } = props.form

  // const [saveLoading, setSaveLoading] = useState(false)
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [ditchStatus, setDitchStatus] = useState([])
  const [oaIdType, setOaIdType] = useState(undefined)
  const [oaidName, setOaidName] = useState('')
  const [creatorName, setCreatorName] = useState('')
  const [recognition, setRecognition] = useState([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [subject, setSubject] = useState([])
  const [mainId, setMainId] = useState('')
  const [collector, setCollector] = useState('')
  const [acountCheckedTotal, setAcountCheckedTotal] = useState(0)
  // const [files, setFiles] = useState([])
  const [oaId, setOaId] = useState('')
  const [detail, setDetail] = useState({})
  const [creatorId, setCreatorId] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
 
  const search = parseSearch(location.search)
  // pageSize = search.limit ? +search.limit : 20

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    api.getFortuneSupplyAgreementStatus().then(data => {
      // console.log(data)
      setDitchStatus(data.DitchStatus)
    })
    // console.log(search)
    if (search.qId) {
      api.getCustomerGetInfo({ type: 1, id: search.qId}).then(res => {
        // console.log(res)
        setFieldsValue({ clientsouce: 1 })
        setOaIdType(1)
        setOaId(res.id)
        setOaidName(res.name)
        setCreatorName(res.principalName)
        setCreatorId(res.creatorId)
        setLoading(true)
        api.getFundAccountList({
          relationType: 1,
          customerId: res.id,
          type: 1
        }).then(res => {
          // console.log(res)
          setSubject(res.list)
          setLoading(false)
        })
      })
    }
    if (search.oaId) {
      api.getCustomerGetInfo({ type: 0, id: search.oaId}).then(res => {
        // console.log(res)
        setFieldsValue({ clientsouce: 0 })
        setOaIdType(0)
        setOaId(res.id)
        setOaidName(res.name)
        setCreatorName(res.principalName)
        setCreatorId(res.creatorId)
        setLoading(true)
        api.getFundAccountList({
          relationType: 0,
          customerId: res.id,
          type: 1
        }).then(res => {
          // console.log(res)
          setSubject(res.list)
          setLoading(false)
        })
      })
    }
    //驳回重提，编辑
    if (search.id) {
      api.getFortuneReceiptDetail({ id: search.id, type: 1 }).then(res => {
        // console.log('编辑', res)
        setDetail(res)
        let data = res
        let files = JSON.parse(res.files)
        if (files.length) {
					files.forEach((item, index) => {
						fileList.push({
							status: 'done',
							uid: index,
							name: item.name,
							url: item.url
						})
					})
					setFileList(files.map((item, index) => ({...item, uid: index})))
				}
        setCreatorName(res.applyName)
        setCollector(res.collector)
        setMainId(res.mainId)
        setOaidName(res.oaName)
        setCreatorId(res.applyId)
        setOaId(res.oaId)
        setOaIdType(res.clientsouce)
        // res.clientsouce == 1 ? setOaIdType(0) : setOaIdType(1)
        setFieldsValue({
          clientsouce: res.clientsouce,
          amount: res.amount,
          payer: res.payer,
          note: res.note
        })
        api.getFundAccountList({
          relationType: res.clientsouce,
          customerId: res.customerId,
          type: 1
        }).then(res2 => {
          // console.log(res)
          setSubject(res2.list)
          setFieldsValue({
            mainId: +res.mainId
          })
        })
        api.getFortuneInvoiceClaim({
          OAID: res.oaId,
          accountId: data.mainId,
          leadId: res.applyId,
          channel: 1
        }).then(res => {
          // console.log(res)
          setCount(res.count)
          setRecognition(res.list)
        }).catch((error) => console.log(error))
        let total = 0
        let keys = []
        rechargenum = []
        // console.log(res.RenKuan.list)
        if(res.RenKuan.list.length){
          for(let i =0; i<res.RenKuan.list.length; i++){
            total = res.RenKuan.list[i].balance - 0 + total
            // console.log(res.RenKuan.list[i]);
            rechargenum.push (res.RenKuan.list[i].rId);
            keys.push(res.RenKuan.list[i].businessId)
          }
          setSelectedRowKeys(keys)
          setAcountCheckedTotal(total)
      }
      })
    }
  }, [])

  //添加
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
      if (!err) {
        // console.log('vals', vals)
        // console.log('fileList', fileList)
        if (acountCheckedTotal >= vals.amount ) {
          setLoading(true)
          api.setFortuneReceiptApply({
            customerId: oaId,
            customerName: oaidName,
            clientsouce: oaIdType,
            mainId: mainId,
            amount: vals.amount,
            payer: vals.payer,
            note: vals.note,
            files: JSON.stringify(uploadList),
            collector: collector,
            selectBalance: acountCheckedTotal,
            rId: rechargenum.join(','),
            id: detail.id ? detail.id : ''
          }).then(res => {
            // console.log(res)
            message.success('申请成功')
            setLoading(false)
            history.push('/protocol/quittance')
          })
        } else {
          message.error('发票余额不足，请仔细确认后提交')
        }
        
      } else {
        message.error('请填写必填内容')
      }
    })
  }

  //取消返回列表页
  const onCancel = () => {
    history.push('/protocol/quittance')
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .PDF, .zip',
		allowSizeType: 2
	})

  const onOAIDs = (e) => {
    // console.log(e.target.value)
    setOaId(e.target.value)
    // if (e.target.value == '') {
    //   return message.warning('请输入ID号')
    // }
  }

  const onBlur = () => {
    // console.log(oaIdType, oaId)
    if (!(oaIdType == 0 || oaIdType == 1)) {
      // console.log(oaIdType)
      return message.warning('请选择ID类型')
    }
    if (oaId == '') {
      return message.warning('请输入ID号')
    }
    setOaidName('')
    setFieldsValue({
      payer: '',
      mainId: undefined
    })
    setCreatorName('')
    setCreatorId('')
    setRecognition([])
    setSelectedRowKeys([])
    setAcountCheckedTotal(0)
    api.getCustomerGetInfo({ id: oaId, type: oaIdType, relation: 1 }).then(data => {
      // console.log('getCustomerGetInfo', data)
      setOaidName(data.name)
      setCreatorName(data.principalName)
      setCreatorId(data.creatorId)
      setLoading(true)
      api.getFundAccountList({
        relationType: oaIdType,
        customerId: data.id,
        type: 1
      }).then(res => {
        // console.log(res)
        setSubject(res.list)
        setLoading(false)
      })
    }).catch(error => {
      // console.log(error)
      setOaidName('')
      setFieldsValue({
        payer: '',
        mainId: undefined
      })
      setCreatorName('')
      setCreatorId('')
      setRecognition([])
      setSelectedRowKeys([])
      setAcountCheckedTotal(0)
      setLoading(false)
    })
  }

  const onSelect = (value) => {
    // console.log(value)
    setOaIdType(''+value)
    setOaId('')
  }

  const onSelects = (value, name) => {
    // console.log(value, name.props.children)
    setCollector(name.props.children)
    setMainId(value)
    if (creatorId == '') {
      return message.warning('id不正确，导致没有填写创建人')
    }
    if (oaId == '') {
      return message.warning('请输入正确的id号码')
    }
    setLoading(true)
    api.getFortuneInvoiceClaim({
      OAID: oaId,
      accountId: value,
      leadId: creatorId,
      channel: 1
    }).then(res => {
      // console.log(res)
      setCount(res.count)
      setRecognition(res.list)
      setLoading(false)
      setSelectedRowKeys([])
      setAcountCheckedTotal(0)
      setFieldsValue({
        amount: ''
      })
    }).catch(() => setLoading(false))
  }

  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    // getEmployeeList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows)
      setSelectedRowKeys(selectedRowKeys)
      setAcountCheckedTotal(0)
      let total = 0
      rechargenum = [];
      if(selectedRows.length){
        for(let i =0; i<selectedRows.length; i++){
          total = selectedRows[i].balance - 0 + total
          // console.log(selectedRows[i]);
          rechargenum.push (selectedRows[i].id);
          
        }
        setAcountCheckedTotal(total)
      }
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
     selectedRowKeys: selectedRowKeys
  }

  return (
    <div className="apply-warp">
      <h1 className="apply-title">收据申请</h1>
      <div className="line"></div>
      <Form onSubmit={handleSubmit} className="apply-form">
        <h3 className="apply-tit">客户信息</h3>
        <div className="underline"></div>
        <Row gutter={24}>
          <Col span={6}>
            <FormItem label="OAID/QID">
              {getFieldDecorator('clientsouce', {
                rules: [{ required: true, message: '请输入' }]
              })(<Select placeholder="请选择" style={{ width: 90 }} dropdownMatchSelectWidth={false} onSelect={onSelect}>
                {
                  ditchStatus.map((v, i) => {
                    return <Option value={v.id} key={i}>{v.name}</Option>
                  })
                }
              </Select>)}
              <Input placeholder="请输入" style={{ width: 160, marginLeft: '10px' }} onChange={onOAIDs} onBlur={onBlur} value={oaId}/>
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="客户名称/代理商名称">
              <div>{oaidName ? oaidName : ''}</div>
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="申请人">
              <div>{creatorName ? creatorName : ''}</div>
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="开收据主体">
              {getFieldDecorator('mainId', {
                rules: [{ required: true, message: '请选择' }]
              })(<Select placeholder="请选择" style={{ width: 200 }} dropdownMatchSelectWidth={false} onSelect={onSelects}>
                {
                  subject.map((v, i) => {
                    return <Option value={v.companyId} key={i}>{v.fullName}</Option>
                  })
                }
              </Select>)}
            </FormItem>
          </Col>
        </Row>
        <div className="line"></div>
        <h3 className="apply-tit">收款信息</h3>
        <div className="underline"></div>
        <div className="apply-money">
          <FormItem label="开据金额">
            {getFieldDecorator('amount', {
              rules: [{ required: true, message: '请输入' }]
            })(<Input placeholder="请输入" style={{ width: 200 }}/>)}
          </FormItem>
          <Table
            dataSource={recognition}
            rowKey="rId"
            className="corp-table"
            loading={loading}
            pagination={{
              pageSize,
              total: count,
              current: currentPage,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
            }}
            scroll={{ x: 1800 }}
            onChange={onChangeTable}
            rowSelection={rowSelection}
          >
            <Column title="客户名称/代理商名称" dataIndex="customerName" fixed="left" width={180} />
            <Column title="OAID/QID" dataIndex="OAID" width={150} fixed="left" />
            <Column title="账户" dataIndex="accountName" width={150} />
            <Column title="类型" dataIndex="type" width={180} />
            <Column title="业务编号" dataIndex="businessNo" width={150} />
            <Column title="付款方户名" dataIndex="payName" width={150} />
            {/* <Column title="认款ID" dataIndex="rId" width={150} /> */}
            <Column title="收款" dataIndex="collect" width={150} />
            <Column title="发票额度支出" dataIndex="pay" width={150} />
            <Column title="发票余额" dataIndex="balance" width={150} />
            <Column title="备注" dataIndex='remark' width={200} />
            <Column title="操作人" dataIndex="operateName" width={200} />
            <Column title="操作时间" dataIndex="operateTime" width={200} />
          </Table>
          <div>已选中发票余额 <span style={{color: 'red'}}>{acountCheckedTotal}</span></div>
        </div>
        <div className="line"></div>
        <h3 className="apply-tit">收据信息</h3>
        <div className="underline"></div>
        <div className="apply-money">
          <Row gutter={24}>
            <Col span={12}>
              <FormItem label="打款方">
                {getFieldDecorator('payer', {
                  rules: [{ required: true, message: '请输入' }]
                })(<Input placeholder="请输入" style={{ width: 200 }} maxLength={50}/>)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="收款方">
                <div>{collector ? collector : ''}</div>
              </FormItem>
            </Col>
          </Row>
        </div>
        <div className="line"></div>
        <h3 className="apply-tit">其他信息</h3>
        <div className="underline"></div>
        <FormItem label="说明" className="apply-FormItem apply-interval apply-required">
          {getFieldDecorator('note')(
            <Input.TextArea placeholder="请输入" maxLength={100} style={{ width: 1200 }} />
          )}
        </FormItem>
        <FormItem label="上传文件">
          <div className="dragger-box" ref={draggerRef}>
            <Dragger
              {...uploadFiles}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
              <p className="ant-upload-hint">
                附件支持格式：GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。
              </p>
            </Dragger>  
          </div>
        </FormItem>
        <FormItem className="apply-btngroup">
          <BtnGroup onCancel={onCancel} className="apply-btngroups" />
        </FormItem>
      </Form>
    </div>
  )
}

export default Form.create()(Apply)