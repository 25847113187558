/**
 * 模块名称: 查看协议模块
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Table,
  Tooltip
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'

const { Column } = Table

const Examine = (props) => {
  const { location } = props
  const search = parseSearch(location.search)
  const [examine, setExamine] = useState([])

  useEffect(() => {
    api.getFortuneSupplyAgreementExecuteDetail({ id: search.id })
      .then(res => setExamine(Object.values(res).filter(v => Array.isArray(v)).reverse()))
  }, [])

  return (
    <div className="examine-wrap">
      {examine.length > 0 && examine.map((list, index) => {
        let ind = 0
        return <div key={index}>
          <div className="examine-title">第{examine.length - index}次执行</div>
          <Table dataSource={list} rowKey="number" pagination={false}>
            <Column title="序号" render={(text, record) => (
              <div>{++ind}</div>
            )} />
            <Column title="起止日期" dataIndex="exec" render={(text, record) => (
              <div>{record.execBegin.slice(0, 10)}至{record.execEnd.slice(0, 10)}</div>
            )} />
            <Column title="点数消耗" dataIndex="point" />
            <Column title="现金消耗" dataIndex="cash" />
            <Column title="返点" dataIndex="returns" />
            <Column title="产品" dataIndex="productsText" render={(text, record) => (
              <Tooltip title={text}>
                <div style={{ color: '#439DDD' }}>{text}</div>
              </Tooltip>
            )} />
            <Column title="订单号" dataIndex="orderNo" render={(text, record) => (
              <Tooltip title={text}>
                <div style={{ color: '#439DDD' }}>{text}</div>
              </Tooltip>
            )} />
            <Column title="附件" dataIndex="fileData" width={300} render={(text, record) => (
              <>{text ? JSON.parse(text).length > 0 && JSON.parse(text).map((item, i) => {
                return <a href={item.url} key={i} target="_blank" style={{ color: '#439DDD' }}>{item.name}<br /></a>
              })
                : null}</>
            )} />
            <Column title="执行状态" dataIndex="status" />
            <Column title="操作人" dataIndex="staffName" />
            <Column title="操作时间" dataIndex="addTime" />
          </Table>
        </div>
      })}
    </div>
  )
}

export default Examine