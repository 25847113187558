/**
 * 模块名称: 补充协议申请页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Select,
  Input,
  DatePicker,
  InputNumber,
  Form,
  message,
  Row,
  Col,
  Upload,
  Icon,
  Tooltip,
  TreeSelect
} from 'antd'
import api from '@/api'
import '../assets/replenish.scss'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import moment from 'moment'

const { Option } = Select
const { TreeNode } = TreeSelect
const { Dragger } = Upload
const FormItem = Form.Item
const { RangePicker } = DatePicker

const Apply = (props) => {
  const { location, history } = props
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form

  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [balanceDate, setBalanceDate] = useState('')
  const [type, setType] = useState('')
  const [projects, setProjects] = useState([])
  const [hasChildren, setHasChildren] = useState(false)
  const [ditchStatus, setDitchStatus] = useState([])
  const [oaIdType, setOaIdType] = useState('')
  const [oaidName, setOaidName] = useState('')
  const [productsText, setProductsText] = useState('')
  const [oaidValue, setOaidValue] = useState('')
  const [projectsResults, setProjectsResults] = useState([])
  const [contractVal, setContractVal] = useState(undefined)

  const search = parseSearch(location.search)

  useEffect(() => {
    api.getFortuneSupplyAgreementStatus().then(data => {
      setDitchStatus(data.DitchStatus)
    })
    if (search.id) {
      api.getFortuneSupplyAgreementDetail({ id: search.id, type: 1 }, true).then(res => {
        const fileLists = JSON.parse(res.filesData)
        api.getFortuneSupplyAgreementProjects({ contractNo: res.contractno }).then(data => {
          formatProjects(data)
        }).catch(error => console.log(error))
        setOaidValue(res.oaId)
        setOaIdType(res.ditch)
        setOaidName(res.oaName)
        setBalanceDate(res.settlementTime)
        setProductsText(res.productsText)
        setProjectsResults(JSON.parse(res.productsDetail))
        setContractVal(res.contractno)
        setFieldsValue({
          oaId: res.oaId,
          contractno: res.contractno,
          time: [moment(res.beginTime), moment(res.endTime)],
          remind: res.remind,
          rule: res.rule,
          describe: res.describe,
          remark: res.remark,
          relationProjects: res.productsId.split(',')
        })
        if (fileLists.length) {
          fileLists.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(fileLists.map((item, index) => ({...item, uid: index})))
        }
      })
    }
  }, [])

  const formatProjects = (data) => {
    setType(data.type)
    const productsDetail = JSON.parse(data.productsDetail)
    if (productsDetail.length === 1) {
      setHasChildren(false)
      setProjects(productsDetail[0].project)
    } else {
      const [{ project }, { product }] = productsDetail
      if (product.length > 0) {
        setHasChildren(true)
        const projectsSimple = project.map((item, index) => {
          return { ...item, product: product[index] }
        })

        const projectsOrigin = project.reduce((prev, cur) => {
          let flag = true
          for (let i = 0, len = prev.length; i < len; i++) {
            if (prev[i].key === cur.key) {
              flag = false
              break
            }
          }
          flag && prev.push(cur)
          return prev
        }, [])

        projectsOrigin.map(item => {
          item.children = []
          projectsSimple.forEach(val => {
            if (item.key === val.key) {
              item.children.push(val.product ? val.product : {})
            }
          })
          return item
        })
        setProjects(projectsOrigin)
      } else {
        setHasChildren(false)
        setProjects(productsDetail[0].project)
      }  
    }
  }

  //添加
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
      if (!err) {
        api.getFortuneSupplyAgreementApply({
          id: search.id,
          oaId: vals.oaId ? vals.oaId : oaidValue,
          oaName: oaidName,
          ditch: oaIdType,
          contractno: vals.contractno,
          beginTime: vals.time[0].format('YYYY-MM-DD'),
          endTime: vals.time[1].format('YYYY-MM-DD'),
          remind: vals.remind,
          settlementTime: balanceDate,
          rule: vals.rule,
          productsId: vals.relationProjects.join(','),
          productsText,
          productsDetail: JSON.stringify(projectsResults),
          filesData: JSON.stringify(uploadList),
          describe: vals.describe,
          remark: vals.remark
        }).then(() => {
          history.push('/protocol/replenish')
        })
      } else {
        message.error('请填写必填内容')
      }
    })
  }

  //取消返回列表页
  const onCancel = () => history.push('/protocol/replenish')

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jpeg, .PDF, .zip',
		allowSizeType: 2
	})

  const handleChange = (value, label) => {
    setProductsText(label.join(','))
    let res = []
    if (hasChildren) {
      res = projects.filter(item => {
        let f = false
        item.selected = item.children.filter(k => {
          let t = false
          for (let i=0; i < value.length; i++) {
            if (k.key === value[i]) {
              t = true
              f = true
              break
            }
          }
          return t
        })
        return f
      })
    } else {
      res = projects.filter(item => {
        let f = false
        for (let i=0; i < value.length; i++) {
          if (item.key === value[i]) {
            f = true
            break
          }
        }
        return f
      })
    }
    setProjectsResults(res)
  }

  // 有效时间变化时计算结算日期
  const onTimeChange = (dates, date) => {
    let dateEnd = date[1]
    if (dates.length > 0) {
      let entryDate = new Date(dateEnd)
      let endY = entryDate.getFullYear()
      let endM = entryDate.getMonth() + 3
      let endD = entryDate.getDate()
      if (endM > 12) {
        endY = entryDate.getFullYear() + 1
        endM = entryDate.getMonth() + 3 - 12
      }
      if (endM === 11 && endD === 31) {
        endM = endM + 1
        endD = 1
      }
      if (endM === 4 && endD === 31) {
        endM = endM + 1
        endD = 1
      }
      if (endM === 6 && endD === 31) {
        endM = endM + 1
        endD = 1
      }
      if (endM === 2 && endD === 31) {
        endM = endM + 1
        endD = 3
      }
      if (endM === 2 && endD === 30) {
        endM = endM + 1
        endD = 2
      }
      if (endM === 2 && endD === 29) {
        endM = endM + 1
        endD = 1
      }
      let formalDate = +endY + "-" + endM + "-" + endD
      setBalanceDate(formalDate)
    } else {
      setBalanceDate('')
    }
  }

  const onContract = (e) => {
    setContractVal(e.target.value)
    if (e.target.value == '') {
      setType('')
      setProjects([])
      return message.warning('请输入合同号')
    }
  }

  const onBlurContract = () => {
    if (!contractVal) {
      setType('')
      setProjects([])
      return message.warning('请输入合同号')
    }
    api.getFortuneSupplyAgreementProjects({ contractNo: contractVal }).then(data => {
      formatProjects(data)
      setProjectsResults([])
      setFieldsValue({
        relationProjects: []
      })
    }).catch(() => {
      setContractVal(undefined)
      setFieldsValue({
        contractno: undefined
      })
    })
  }

  const onOaID = (e) => setOaidValue(e.target.value)

  const onBlur = () => {
    if (oaidValue == '') {
      return message.warning('请输入ID号')
    }
    if (oaIdType !== '') {
      api.getFortuneSupplyAgreementCustomerInfo({ oaId: oaidValue, type: oaIdType }).then(data => {
        if (Array.isArray(data)) {
          setOaidName(data[0].name)
        } else {
          setOaidName(data.name)
        }
      }).catch(() => setOaidName(''))
    } else {
      message.warning('请选择ID类型')
    }
  }

  const onSelect = (value) => {
    setOaIdType(value)
    if (oaidValue != '') {
      setOaidValue('')
      setFieldsValue({
        oaId: ''
      })
      setOaidName('')
    }
  }

  return (
    <>
      <div className="apply-warp">
        <h1 className="apply-title">补充协议申请</h1>
        <div className="line"></div>
        <Form onSubmit={handleSubmit} className="apply-form">
          <h3 className="apply-tit">客户信息</h3>
          <div className="underline"></div>
          <Row gutter={24}>
            <Col lg={8} xxl={6}>
              <FormItem label="OAID/代理商ID" className="apply-FormItem apply-required">
                <Select placeholder="请选择" style={{ width: 90 }} dropdownMatchSelectWidth={false} onSelect={onSelect} value={oaIdType}>
                  {
                    ditchStatus.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>
                {getFieldDecorator('oaId', {
                  rules: [{ required: true, message: '请输入和选择' }]
                })(<Input placeholder="请输入" style={{ width: 160, marginLeft: '10px' }} onChange={onOaID} onBlur={onBlur}/>)}
              </FormItem>
            </Col>
            <Col lg={8} xxl={6}>
              <FormItem label="OA客户名称/代理商名称" className="apply-FormItem apply-required">
                <Input readOnly style={{ width: 240 }} value={oaidName}/>
              </FormItem>
            </Col>
            <Col lg={8} xxl={6}>
              <FormItem label="合同号" className="apply-FormItem">
                {getFieldDecorator('contractno', {
                  rules: [{ required: true, message: '请输入' }]
                })(<Input placeholder="请输入" style={{ width: 240 }} onChange={onContract} onBlur={onBlurContract}/>)}
              </FormItem>
            </Col>
            <Col lg={8} xxl={6}>
              <FormItem label="合同类型" className="apply-FormItem">
                <Input readOnly style={{ width: 240 }} value={type}/>
              </FormItem>
            </Col>
            <Tooltip title="选择哪天，即在每月第几日提醒。">
              <Col lg={8} xxl={6}>
                <FormItem label="有效时间" className="apply-FormItem apply-required">
                  {getFieldDecorator('time', {
                  rules: [{ required: true, message: '请选择' }]
                })(<RangePicker style={{ width: 280 }} onChange={onTimeChange} />)}
                </FormItem>
              </Col>
            </Tooltip>
            <Col lg={8} xxl={6}>
              <FormItem label="结算提醒日期" className="apply-FormItem">
                {getFieldDecorator('remind', {
                  rules: [{ required: true, message: '请选择' }]
                })(<InputNumber min={1} max={28} style={{ width: 240 }} placeholder="请输入" />)}
              </FormItem>
            </Col>
            <Col lg={8} xxl={6}>
              <FormItem label="结算日期" className="apply-FormItem">
                <Input readOnly style={{ width: 240 }} value={balanceDate}/>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label="政策" className="apply-FormItem apply-interval apply-required">
                {getFieldDecorator('rule', {
                  rules: [{ required: true, message: '请输入' }]
                })(
                  <Input.TextArea placeholder="请输入" maxLength={100} style={{ width: 1200 }} />
                )}
              </FormItem>
            </Col>
          </Row>
          <div className="line"></div>
          <h3 className="apply-tit">关联项目</h3>
          <div className="underline"></div>
          <FormItem label="关联项目" className="apply-FormItem apply-interval apply-required">
            {getFieldDecorator('relationProjects', {
              rules: [{ required: true, message: '请添加关联项目' }]
            })(
              <TreeSelect
                showSearch
                style={{ width: 400 }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="请选择"
                allowClear
                multiple
                treeDefaultExpandAll
                onChange={handleChange}
                disabled={projects.length === 0}
              >
                {projects.length > 0 && projects.map(item => <TreeNode value={item.key} title={item.label} key={item.key} disabled={hasChildren}>
                  {hasChildren && item.children.map(child => <TreeNode value={child.key} title={child.label} key={child.key} />)}
                </TreeNode>)}
              </TreeSelect>
            )}
          </FormItem>
          <div className="line"></div>
          <h3 className="apply-tit">其他信息</h3>
          <div className="underline"></div>
          <FormItem label="上传附件">
            <div className="dragger-box" ref={draggerRef}>
              <Dragger
                {...uploadFiles}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">
                  附件支持格式：GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过5M，文件数量不超过5个。
                </p>
              </Dragger>  
            </div>
          </FormItem>
          <FormItem label="附件说明" className="apply-FormItem apply-interval apply-required">
            {getFieldDecorator('describe')(
              <Input.TextArea placeholder="请输入" maxLength={100} style={{ width: 1200 }} />
            )}
          </FormItem>
          <FormItem label="备注" className="apply-FormItem apply-interval apply-required">
            {getFieldDecorator('remark')(
              <Input.TextArea placeholder="请输入" maxLength={100} style={{ width: 1200 }} />
            )}
          </FormItem>
          <FormItem className="apply-btngroup">
            <BtnGroup onCancel={onCancel} className="apply-btngroups" />
          </FormItem>
        </Form>
      </div>
    </>
  )
}

export default Form.create()(Apply)