/**
 * 模块名称: 执行、修改页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  Input,
  DatePicker,
  Form,
  message,
  Row,
  Col,
  Upload,
  Icon,
  TreeSelect,
} from 'antd'
import api from '@/api'
import BtnGroup from '@/components/BtnGroup'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import moment from 'moment'

const { TreeNode } = TreeSelect
const { Dragger } = Upload
const FormItem = Form.Item
const { RangePicker } = DatePicker

const Execute = (props) => {
  const { location, history } = props
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form

  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [projects, setProjects] = useState([])
  const [hasChildren, setHasChildren] = useState(false)
  const [productsText, setProductsText] = useState('')

  const search = parseSearch(location.search)

  useEffect(() => {
    api.getFortuneSupplyAgreementDetail({ id: search.id, type: 0 }).then(data => {
      const productsDetail = JSON.parse(data.productsDetail)
      if (productsDetail[0].children === undefined) {
        setHasChildren(false)
      } else {
        setHasChildren(true)
      }
      setProjects(productsDetail)
    }).catch(error => console.log(error))

    if (search.execid) {
      api.getFortuneSupplyAgreementOrderInfo({ exId: search.execid }).then(res => {
        const fileLists = JSON.parse(res.fileData)
        setFieldsValue({
          exec: [moment(res.execBegin), moment(res.execEnd)],
          point: res.point,
          cash: res.cash,
          returns: res.returns,
          fileDescribe: res.fileDescribe,
          relationProjects: res.products.split(',')
        })
        if (fileLists.length) {
          fileLists.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(fileLists.map((item, index) => ({...item, uid: index})))
        }
        setProductsText(res.productsText)
      })
    }
  }, [])

  //添加
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (!fileList.length > 0) {
          return message.warning('请上传附件')
        }
        api.getFortuneSupplyAgreementExecute({
          said: search.id,
          execBegin: vals.exec[0].format('YYYY-MM-DD'),
          execEnd: vals.exec[1].format('YYYY-MM-DD'),
          point: vals.point,
          cash: vals.cash,
          returns: vals.returns,
          products: vals.relationProjects.join(','),
          productsText,
          fileData: JSON.stringify(fileList),
          fileDescribe: vals.fileDescribe
        }).then(() => {
          history.push('/protocol/replenish')
        })
      } else {
        message.error('请填写必填内容')
      }
    })
  }

  //取消返回列表页
  const onCancel = () => history.push('/protocol/replenish')

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jepg, .PDF, .doc, .docx, .csv, .xlsx, .zip',
		allowSizeType: 2
	})

  const handleChange = (value, label) => setProductsText(label.join(','))

  return (
    <>
      <div className="apply-warp">
        <h1 className="apply-title">消耗执行</h1>
        <div className="apply-serial">业务编号：<span>{search.agreeNo}</span></div>
        <div className="line"></div>
        <Form onSubmit={handleSubmit} className="apply-form">
          <h3 className="apply-tit">消耗信息</h3>
          <div className="underline"></div>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="选择日期" className="apply-FormItem apply-required">
                {getFieldDecorator('exec', {
                   rules: [{ required: true, message: '请选择' }]
                })(<RangePicker style={{ width: 280 }} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="点数消耗" className="apply-FormItem">
                {getFieldDecorator('point', {
                   rules: [{ required: true, message: '请选择' }]
                })(<Input placeholder="请输入" style={{ width: 240 }} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="现金消耗" className="apply-FormItem">
                {getFieldDecorator('cash', {
                   rules: [{ required: true, message: '请选择' }]
                })(<Input placeholder="请输入" style={{ width: 240 }} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="返点" className="apply-FormItem">
                {getFieldDecorator('returns', {
                   rules: [{ required: true, message: '请选择' }]
                })(<Input placeholder="请输入" style={{ width: 240 }} />)}
              </FormItem>
            </Col>
          </Row>
          <div className="line"></div>
          <h3 className="apply-tit">关联项目</h3>
          <div className="underline"></div>
          <FormItem label="关联项目" className="apply-FormItem apply-interval apply-required">
            {getFieldDecorator('relationProjects', {
              rules: [{ required: true, message: '请添加关联项目' }]
            })(
              <TreeSelect
                showSearch
                style={{ width: 400 }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="请选择"
                allowClear
                multiple
                treeDefaultExpandAll
                onChange={handleChange}
              >
                {projects.length > 0 && projects.map(item => <TreeNode value={item.key} title={item.label} key={item.key} disabled={hasChildren}>
                  {hasChildren && item.selected.map(child => <TreeNode value={child.key} title={child.label} key={child.key} />)}
                </TreeNode>)}
              </TreeSelect>
            )}
          </FormItem>
          <div className="line"></div>
          <h3 className="apply-tit">其他信息</h3>
          <div className="underline"></div>
          <FormItem label="上传附件">
            <span style={{ color: 'red', position: 'relative', left: '-18px', top: '-38px'}}>*</span>
            <div className="dragger-box" ref={draggerRef} style={{ width: 600 }}>
              <Dragger
                {...uploadFiles}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">
                  支持扩展名：GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip、.rar、JEPG 单个文件大小不超过5M，不超过5个附件。
                  </p>
              </Dragger>
            </div>
          </FormItem>
          <FormItem label="附件说明" className="apply-FormItem apply-interval apply-required">
            {getFieldDecorator('fileDescribe')(
              <Input.TextArea placeholder="请输入" maxLength={100} style={{ width: 1200 }} />
            )}
          </FormItem>
          <FormItem className="apply-btngroup">
            <BtnGroup onCancel={onCancel} className="apply-btngroups" />
          </FormItem>
        </Form>
      </div>
    </>
  )
}

export default Form.create()(Execute)