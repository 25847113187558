import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Quittance from './Quittance'
import Details from './Details'
import Apply from './Apply'

const QuittanceIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/details'} component={Details} />
      <Route path={match.path + '/apply'} component={Apply} />
      <Route component={Quittance} />
    </Switch>
  )
}

export default QuittanceIndex