/**
 * 模块名称: 发票详情 - 冲红
 * @author yue
 */
import React, {useEffect, useRef, useState} from 'react'
import {parseSearch} from '@/utils'
import { upLoadModule } from '@/utils/common'
import {Form, Icon, Input, InputNumber, message, Spin, Upload} from 'antd'

import PublicDetail from './PublicDetail'
import BtnGroup from "../../../components/BtnGroup";
import api from "../../../api";

const FormItem = Form.Item
const {Dragger} = Upload
let disabled = true

const Modify = (props) => {
    const {location, history} = props
    const {getFieldDecorator, validateFields, setFieldsValue} = props.form
    const [params, setParams] = useState([])
    const [spinLoading, setSpinLoading] = useState(false);
    const [editId, setEditId] = useState(undefined);
    const [detail, setDetail] = useState({})

    const draggerRef = useRef(null)
    const [fileList, setFileList] = useState([])

    useEffect(() => {
        let search = parseSearch(location.search);
        setParams(search);
        setEditId(search.editId);
    }, [])

    //跳转到列表页
    const onCancel = () => {
        history.push({
            pathname: '/protocol/invoice'
        })
    }

    // 文件上传
    const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .pptx, .PDF, .csv, .xlsx',
		allowSizeType: 4
    })
    
    //设置发票详情数据
    const getDetail = (detailData) => {
        setDetail(detailData);

        if (editId) {
            setFieldsValue({
                invnumber: detailData.chonghong.invnumber ? detailData.chonghong.invnumber : undefined,
                amount: detailData.chonghong.amount ? detailData.chonghong.amount : undefined,
                explain: detailData.chonghong.explain ? detailData.chonghong.explain : undefined
            })
        }

        if (detailData.qualification!==null && detailData.qualification.length) {
            detailData.qualification.forEach((item, index) => {
                fileList.push({
                    status: 'done',
                    uid: index,
                    name: item.name,
                    url: item.url
                })
            })
            setFileList(detailData.qualification.map((item, index) => ({...item, uid: index})))
        }
    }

    //提交表单
    const formSubmit = (e) => {
        setSpinLoading(true);
        e.preventDefault()
        if (disabled) {
            disabled = false
            let timer = setTimeout(() => {
                disabled = true
                clearTimeout(timer)
            }, 1000)
        } else {
            setSpinLoading(false);
            message.error('请不要频繁点击')
            return
        }

        validateFields((err, vals) => {
            if (!err) {
                let subParams = Object.assign({}, vals)

                //判断是否为驳回重提
                if (editId) {
                    subParams.id = editId
                    subParams.invId = detail.primaryId//取原始发票id
                } else {
                    subParams.invId = detail.id//取原始发票id
                }

                let uploadList = []
                for (let i = 0, k = fileList.length; i < k; i++) {
                    if (fileList[i].status !== 'removed') {
                        uploadList.push({
                            url: fileList[i].url,
                            name: fileList[i].name
                        })
                    }
                }

                //附件
                subParams.qualification = uploadList

                //原发票信息发票类型
                
                subParams.invType = detail.invtype

                //数据格式化
                subParams.amount = Number.parseFloat(subParams.amount)

                //冲红金额大于待清理金额，提示：冲红金额大于待清理金额，请重新输入。
                console.log(subParams.amount, Number.parseFloat(detail.clearedMoney))
                if (detail.colleStatusName !== '已收款') {
                    if (subParams.amount > Number.parseFloat(detail.clearedMoney)) {
                        message.error('冲红金额大于待清理金额，请重新输入。');
                        setSpinLoading(false);
                        return;
                    }
                }
                api.invoiceRed(subParams)
                    .then(res => {
                        message.success('操作成功')
                        //跳转列表
                        onCancel();
                    })
                    .catch(() => {
                        setSpinLoading(false);
                    });
            } else {
                setSpinLoading(false);
            }
        })
    }

    return (
        <Spin spinning={spinLoading}>
            <div className="add-newinvoice-detail">
                {/*表单信息*/}
                <Form onSubmit={formSubmit}>
                    {/*展示信息*/}
                    <PublicDetail params={params} getDetail={getDetail} form={props.form} type={'modify'}/>

                    <div className="blank-line"></div>
                    <div className="ask-info">
                        <div className="record-list-title">冲红信息</div>
                        <FormItem label="冲红发票号">
                            {getFieldDecorator('invnumber', {
                                rules: [{required: true, message: '请输入冲红发票号'}]
                            })
                            (<Input placeholder="请输入冲红发票号" style={{width: 180}}/>)}
                        </FormItem>
                        <FormItem label="冲红金额">
                            {getFieldDecorator('amount', {
                                rules: [{required: true, message: '请输入冲红金额'}]
                            })
                            (<InputNumber placeholder="请输入冲红金额" style={{width: 180}}/>)}
                        </FormItem>
                        <div>
                            <FormItem label="上传资质">
                                <div className="dragger-box" style={{width: 580}} ref={draggerRef}>
                                    <Dragger
                                        {...uploadFiles}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox"/>
                                        </p>
                                        <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                        <p className="ant-upload-hint">
                                            支持扩展名：.rar, .zip, .doc, .docx, .pdf, .jpg, .pptx, .PDF, .csv, .xlsx
                                        </p>
                                    </Dragger>
                                </div>
                            </FormItem>
                        </div>
                        <div>
                            <FormItem label="冲红说明" style={{width: 590}}>
                                {getFieldDecorator('explain')(
                                    <Input.TextArea className="content-area" placeholder="请输入冲红说明" rows={3}
                                                    style={{width: "100%"}} maxLength={100}/>
                                )}
                            </FormItem>
                        </div>
                    </div>
                    <div className="btns">
                        <BtnGroup onCancel={onCancel} loading={false}/>
                    </div>
                </Form>
            </div>
        </Spin>
    )
}
export default Form.create()(Modify)