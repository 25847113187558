/**
 * 模块名称: 收据详情
 * @author liujingxue@372163.com
 */

import React, {useEffect, useState} from 'react'
import {Button, Descriptions, Icon, Input, message, Modal, Popconfirm, Select, Spin, Steps, Table, Form} from 'antd'
import api from '@/api'
import '../assets/quittance.scss'
import {parseSearch} from '@/utils'
import CryptoJS from 'crypto-js'

const FormItem = Form.Item
const {Step} = Steps
const dItem = Descriptions.item
const {Option} = Select
const {Column} = Table

let areaTxt = '', result = ''
let pageSize = 5
let currentPage = 1

const Details = (props) => {

    const {history, location, match} = props
    const { getFieldDecorator, validateFields, setFieldsValue } = props.form

    const [detail, setDetail] = useState({})
    const [flow, setFlow] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [pageId, setPageId] = useState('')
    const [auditRecord, setAuditRecord] = useState([])
    const [oldAuditRecord, setOldAuditRecord] = useState([])
    const [loading, setLoading] = useState(true)
    const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
    // const [attList, setAttList] = useState([])
    const [oldData, setOldData] = useState(null)
    const [RenKuan, setRenKuan] = useState([])
    const [receiptNumber, setReceiptNumber] = useState('')
    // const [departList, setDepartList] = useState([])
    // const [receiverId, setReceiverId] = useState('')
    // const [receiverName, setReceiverName] = useState('')
    const [files, setFiles] = useState([])

    //领取人id
    const [receiveId, setReceiveId] = useState(undefined)
    //领取人列表
    const [receiveList, setReceiveList] = useState([])
    //操作权限
    const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
    const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
    const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
    const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

    const search = parseSearch(location.search)

    useEffect(() => {
        currentPage = search.page ? +search.page : 1
        let plaintext = search.sign
        let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

        if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
            areaTxt = ''
            result = ''
            if (location.search) {
                setPageId(parseSearch(location.search).id)
                getLeaveOutRow(parseSearch(location.search).id)
            } else {
                history.push({
                    pathname: '/protocol/quittance'
                })
            }
        } else {
        message.error('没有本页访问权限')
        setTimeout(() => {
            history.go(-2)
        }, 1000)
        }
        // api.getFortuneContractDepartList({limit: global.paramsLimit}).then(data => {
        //     // console.log('departList', data)
        //     setDepartList(data)
        // })
    }, [])

    const geReceiveList = (vals) => {
      setFieldsValue({ receiveId: '' })
      let val = vals.trim()
      if (val) {
        return api.invoiceReceive({ name: val }).then(res => {
          setReceiveList(res)
        })
      } else {
        return (new Promise(function () {
          setReceiveList([])
        }))
      }
    }

    //获取日常办公审批单条的详情
    const getLeaveOutRow = (id) => {
        api.getFortuneReceiptDetail({id: id, type: 0}).then(res => {
            if (parseSearch(location.search).msgId) {
                api.setMessageReaded({ids: [parseSearch(location.search).msgId]})
            }
            if (JSON.stringify(res) !== "[]") {
                let data = Object.assign({}, res)
                if (data.status === 0) data.current = 0
                if (data.status === 1) data.current = 1
                if (data.status === 2) data.current = 2
                if (data.status === 3) data.current = 3
                // console.log(data)
                setDetail(data)
                if (data.receiverFlag) {
                  geReceiveList(data.applyName).then(function () {
                    setFieldsValue({
                      receiveId: data.applyId ? data.applyId : undefined,
                    })
                  })
                }
            
                //初始化领取人信息
                setReceiveId(JSON.stringify({
                  "id": data.applyId,
                  "staffName": data.applyName,
                }))

                data.files && setFiles(JSON.parse(data.files))
                // data.checkInData ? setAttList([data.checkInData]) : setAttList([])
                let auditFlow = data.audit_flow
                // let auditLog = data.audit_log[data.audit_log.length - 1].status
                // console.log(auditLog)
                auditFlow.forEach((item, index, self) => {
                    if (item.persons && item.persons.length) {
                        self[index].persons = item.persons.join('，')
                    }
                })
                setFlow(auditFlow)
                setAuditRecord(data.audit_log)
                setOldAuditRecord(data.old_audit_log)
                setRenKuan(data.RenKuan.list)

                if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
                    setHasChargerPower(data.auditAuth.hasChargerPower)
                    setReplenish(data.auditAuth.replenish)
                    setReturnUp(data.auditAuth.return_up)
                    setRejectMsgRequire(data.auditAuth.reject_msg_require)
                } else {
                    setHasChargerPower(false)
                    setReplenish(false)
                    setReturnUp(false)
                    setRejectMsgRequire(false)
                }
                setOldData(data.oldData)
            } else {
                setDetail({})
                setFlow([])
                setAuditRecord([])
                setOldAuditRecord([])
                setOldData(null)
                setRenKuan([])
            }
            setLoading(false)
        })
    }
    //点击4个审批的按钮
    const leaveOutAudit = (no) => {
        areaTxt = ''
        result = no

        if (no == 4) {//eslint-disable-line
          setResPlaceholder('请输入审批意见')
          setModalVisible(true)
        } else if (no == 1) {//eslint-disable-line
            //审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
            // setResPlaceholder('请输入审批意见')
            setModalVisible(true)
        } else if (no == 2 || no == 3) {//eslint-disable-line
            setLoading(true)
            api.setFortuneReceiptAudit({
              id: pageId,
              type: result,
              remark: areaTxt
            }).then(res => {
              //todo 转上级或者补充资料 返回列表页
              let timer = setTimeout(() => {
                  history.push({
                      pathname: '/protocol/quittance'
                  })
                  clearTimeout(timer)
              }, 1000)
            }).catch(err => setLoading(false))
        } else {
          validateFields((err, vals) => {
            if (!err) {    
              if (no == 0) {//eslint-disable-line
                setResPlaceholder('请输入审批意见')
                setModalVisible(true)
              }
            }
          })
        }
    }

    /**
     * 选择转出人操作
     * @param option
     */
    const changeReceiveList = (option, value) => {
      setReceiveId(option.props['data-value'])
    }

    //获取领取人信息
    function getReceiveData() {
      let receiveData = JSON.parse(receiveId);

      let id = '';
      let name = '';

      if (receiveData != undefined) {
        id = receiveData.id;
        name = receiveData.staffName;
      }
      return { id, name };
    }

    const oprateHandleOk = () => {
        if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
            message.warning('请输入至少1个字符')
            return
        }

        let params = {
            id: pageId,
            type: result == 4 ? 0 : result,
            remark: areaTxt
        }

        //仅通过允许填写
        if (result === 0) {
          let receiveData = getReceiveData()
          params.recReceiveId = receiveData.id;
          params.recReceiveName = receiveData.name;
        }

        setModalVisible(false)
        setLoading(true)
        // saveAppealAudit
        api.setFortuneReceiptAudit(params).then(res => {
            message.success('审批成功')
            setModalVisible(false)
            getLeaveOutRow(pageId)
        }).catch(error => setLoading(false))
    }
    //取消弹窗
    const oprateHandleCancel = () => {
        setModalVisible(false)
    }
    //同意的样式
    const agreeNode = () => {
        return result === 0 || result === 4 ? <>
            通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>
        </> : <>
            驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="red"/>
        </>
    }
    //审批意见
    const getAreaTxt = (e) => {
        areaTxt = e.target.value.trim()
    }

    const onChangeTable = (pagination) => {
        // console.log(pagination)
        currentPage = pagination.current
        pageSize = pagination.pageSize
        history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
        // getEmployeeList()
    }

    //收据号
    const ReceiptNumber = (e) => {
        // console.log(e.target.value)
        setReceiptNumber(e.target.value)
    }

    //领取人
    // const onReceiver = (id, val) => {
    //     // console.log(id, val.props.children)
    //     setReceiverId(id)
    //     setReceiverName(val.props.children)
    // }

    //待领取
    const toReceive = () => {
        if (receiptNumber == '') {
          return message.warning('请输入收据号')
        }
        setLoading(true)
        api.setFortuneReceiptRecNumber({
            id: pageId,
            receiptNumber: receiptNumber
        }).then(res => {
            // console.log(res)
            getLeaveOutRow(pageId)
            setLoading(false)
        }).catch(err => setLoading(false))
    }

    //已回确定
    const onConfirmHasBeenBack = () => {
        setLoading(true)
        api.setFortuneReceiptWriteReturn({id: pageId}).then(res => {
          // console.log(res)
          getLeaveOutRow(pageId)
          setLoading(false)
        }).catch(err => setLoading(false))
    }

    //已回取消
    const onCancelHasBeenBack = () => {

    }

    //确定核销
    const verification = () => {
      setLoading(true)
      api.setFortuneReceiptWriteOff({id: pageId}).then(res => {
        // console.log(res)
        getLeaveOutRow(pageId)
        setLoading(false)
      }).catch(err => setLoading(false))
    }

    //取消核销
    const verificationCancel = () => {

    }

    //退收据
    const ReturnReceipt = () => {
    //   console.log('退收据')
      setLoading(true)
      api.setFortuneReceiptReturn({id: pageId}).then(res => {
        // console.log(res)
        getLeaveOutRow(pageId)
        setLoading(false)
      }).catch(err => setLoading(false))
    }

    //取消退收据
    const ReturnReceiptCancel = () => {
    }

    return (
        <Spin spinning={loading}>
            <div className="step-wrap" style={{marginTop: '20px', marginBottom: '20px', marginLeft: '10px'}}>
                <h1 style={{marginLeft: '20px', color: '#000', fontSize: '18px', fontWeight: 800}}>收据详情</h1>
                <div>收据业务编号：<span style={{color: '#000', fontSize: '16px'}}>{detail.receiptNo}</span></div>
            </div>
            <div className="line"></div>
            <div className="step-wrap" style={{marginTop: '20px', marginBottom: '20px'}}>
                <div className="record-list-title"
                     style={{color: '#000', fontSize: '16px', fontWeight: 800, marginBottom: '10px'}}>当前进度
                </div>
                <Steps current={detail.current}>
                    {
                        flow.map((item, index, self) => {
                            let status = 'wait'
                            if (item.time && item.time.length > 1) {
                                status = 'process'
                            }
                            return <Step status={status} title={item.nodeName} key={index} description={
                                <>
                                    {item.persons && <div title={item.persons}>{item.persons}</div>}
                                    <div title={item.statusName}>{item.statusName}</div>
                                    <div title={item.time}>{item.time}</div>
                                </>
                            }/>
                        })
                    }
                </Steps>
            </div>
            <div className="line"></div>
            <Descriptions title="客户信息" layout="vertical" column={4} className="order-detail-title"
                          style={{borderBottom: 'none', marginTop: '10px'}}>
                <dItem label="OAID/QID">{detail.clientsouce == 0 ? 'OAID：' : 'QID：'}{detail.oaId}</dItem>
                <dItem label={detail.clientsouce == 0 ? '客户名称' : '代理商名称'}>{detail.oaName}</dItem>
                <dItem label="申请人">{detail.applyName}</dItem>
                <dItem label="收据账户">{detail.collector}</dItem>
                <dItem label="收据号">{detail.receiptNumber}</dItem>
                <dItem label="领取人">{detail.receiverName}</dItem>
            </Descriptions>
            <div className="line"></div>
            <Descriptions title="收款信息" layout="vertical" column={2} className="order-detail-title"
                          style={{marginTop: '10px', borderBottom: 'none'}}>
                {
                    detail.collectStatus == 0 ? '' : 
                    <dItem label="收款状态">{detail.collectStatus}</dItem>
                }
                <dItem label="开据金额">{detail.amount}</dItem>
            </Descriptions>
            <Table
                dataSource={RenKuan}
                rowKey="rId"
                style={{marginBottom: 10}}
                className="today-info"
                loading={loading}
                expandedRowRender={(record) => {
                    return <Table
                        rowKey="id"
                        dataSource={record.claimItemsInfo && record.claimItemsInfo.list}
                        pagination={false}
                    >
                        <Column title="到款方式" dataIndex="payWayName"/>
                        <Column title="到款银行" dataIndex="bank" render={(text, record) => (
                            <div>{record.moneyBackInfo ? record.moneyBackInfo.bank: ''}</div>
                        )}/>
                        <Column title="到款日期" dataIndex="date" render={(text, record) => (
                            <div>{record.moneyBackInfo ? record.moneyBackInfo.date : ''}</div>
                        )}/>
                        <Column title="付款方户名" dataIndex="name" render={(text, record) => (
                            <div>{record.moneyBackInfo ? record.moneyBackInfo.name : ''}</div>
                        )}/>
                        <Column title="付款方账号" dataIndex="account" render={(text, record) => (
                            <div>{record.moneyBackInfo ? record.moneyBackInfo.account : ''}</div>
                        )}/>
                        <Column title="到款金额" dataIndex="amount" render={(text, record) => (
                            <div>{record.moneyBackInfo ? record.moneyBackInfo.amount : ''}</div>
                        )}/>
                        <Column title="认款金额" dataIndex="rkQuota"/>
                        <Column title="可认余额" dataIndex="balance" render={(text, record) => (
                            <div>{record.moneyBackInfo ? record.moneyBackInfo.balance : ''}</div>
                        )}/>
                        <Column title="到款备注" dataIndex="note" render={(text, record) => (
                            <div>{record.moneyBackInfo ? record.moneyBackInfo.note : ''}</div>
                        )}/>
                    </Table>
                }}
                pagination={false}
                // scroll={{ x: 1800 }}
                // onChange={onChangeTable}
            >
                <Column title="业务编号" dataIndex="businessNo"/>
                <Column title="客户名称" dataIndex="client"/>
                <Column title="OAID" dataIndex="clientId"/>
                <Column title="账户" dataIndex="companyName"/>
                <Column title="类型" dataIndex="businessType"/>
                {/*<Column title="付款方户名" dataIndex="bankName" width={150} render={(text, record) => (*/}
                {/*  <div style={{ color: '#439DDD'}}>{text}</div>*/}
                {/*)}/>*/}
                {/* <Column title="认款ID" dataIndex="rId" width={150} /> */}
                <Column title="收款" dataIndex="collectMoney"/>
                <Column title="发票额度支出" dataIndex="pay"/>
                <Column title="发票余额" dataIndex="balance"/>
                <Column title="备注" dataIndex='remark'/>
                <Column title="操作人" dataIndex="userName"/>
                <Column title="操作时间" dataIndex="operateTime"/>
            </Table>
            {/* {
        RenKuan.length > 0 && RenKuan.map((v, i) => {
          return <div key={i}>
            <Table
              dataSource={v.DaoKuan}
              rowKey={record => record.id}
              className="corp-table"
              loading={loading}
              pagination={{
                pageSize,
                current: currentPage,
              }}
              scroll={{ x: 1000 }}
              onChange={onChangeTable}
            >
              <Column title="到款方式" dataIndex="payway" fixed="left" width={170} />
              <Column title="到款银行" dataIndex="bank" width={180} fixed="left" render={(text, record) => (
                <div style={{ color: '#439DDD'}}>{text}</div>
              )}/>
              <Column title="到款日期" dataIndex="mnbAddTime" width={180} />
              <Column title="付款方户名" dataIndex="accountType" width={180} render={(text, record) => (
                <div style={{ color: '#439DDD'}}>{text}</div>
              )}/>
              <Column title="付款方账号" dataIndex="businessNo" width={180} />
              <Column title="到款金额" dataIndex="mnbkDkAmount" width={180} />
              <Column title="认款金额" dataIndex="rkQuota" width={180} />
              <Column title="可认余额" dataIndex="balance" width={180} />
              <Column title="到款备注" dataIndex="note" width={180} />
            </Table>
          </div>
        })
      } */}
            <div className="line"></div>
            <Descriptions title="收据信息" layout="horizontal" column={2} className="order-detail-title"
                          style={{marginTop: '10px', borderBottom: 'none'}}>
                <dItem label="打款方">{detail.payer}</dItem>
                <dItem label="收款方">{detail.collector}</dItem>
            </Descriptions>
            <div className="line"></div>
            <Descriptions title="其他信息" layout="horizontal" column={1} className="order-detail-title"
                          style={{marginTop: '10px', borderBottom: 'none'}}>
                <dItem label="附件">
                    {
                        Array.isArray(files) ? files.map((item, i) => {
                            return <div key={i}><a href={item.url} target="_blank"
                                                   rel="noopener noreferrer">{item.name}</a></div>
                        }) : <div>{detail.files}</div>
                    }
                </dItem>
                <dItem label="说明">
                    <>
                        {detail.note ? detail.note : '无'}
                    </>
                </dItem>
            </Descriptions>
            <div className="line"></div>
            <div className="record-list" style={{marginTop: '8px', marginBottom: '14px'}}>
                <Descriptions title="审批列表" layout="vertical" className="pro-detail-title" column={4}
                              style={{borderBottom: "none", marginBottom: "-30px"}}>
                    <dItem label="审批人"></dItem>
                    <dItem label="审批时间"></dItem>
                    <dItem label="审批状态"></dItem>
                    <dItem label="审批意见"></dItem>
                </Descriptions>
                {
                    oldAuditRecord.map((item, index) => {
                        return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index}
                                             style={{borderBottom: "none", marginBottom: "-10px"}}>
                            <dItem label="">{item.userInfo.staffName}</dItem>
                            <dItem label="">{item.addTime}</dItem>
                            <dItem label="">{item.status}</dItem>
                            <dItem label="">{item.chargeMsg}</dItem>
                        </Descriptions>
                    })
                }
                {
                    auditRecord.map((item, index) => {
                        return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index}
                                             style={{borderBottom: "none", marginBottom: "-10px"}}>
                            <dItem label="">{item.userInfo.staffName}</dItem>
                            <dItem label="">{item.addTime}</dItem>
                            <dItem label="">{item.status}</dItem>
                            <dItem label="">{item.chargeMsg}</dItem>
                        </Descriptions>
                    })
                }
                {
                    detail.receiverFlag && <FormItem label="领取人">
                      {getFieldDecorator('receiveId', {
                        initialValue: receiveId,
                        rules: [{ required: result == 1 ? false : true, message: '请输入领取人' }]
                      })(
                        <Select
                          showSearch
                          showArrow
                          placeholder="请输入领取人"
                          onSearch={geReceiveList}
                          // onChange={handleChangeTransferInUserList}
                          onChange={(value, option) => changeReceiveList(option, value)}
                          notFoundContent='未搜索到相关领取人'
                          filterOption={false}
                          style={{ width: 180 }}
                        >
                          {receiveList.map(item => <Option key={item.id} value={item.id} data-value={JSON.stringify(item)}>{item.staffName} {item.staffNo}</Option>)}
                        </Select>
                      )}
                    </FormItem>
                    // <div style={{marginLeft: '40px', marginTop: '20px', marginBottom: '20px'}}>
                    //     领取人：<Select placeholder="请选择" style={{width: 200}} dropdownMatchSelectWidth={false}
                    //                 onSelect={onReceiver}>
                    //     {
                    //         departList.map((v, i) => {
                    //             return <Option value={v.id} key={i}>{v.name}</Option>
                    //         })
                    //     }
                    // </Select>
                    // </div>
                }
                {
                    detail.recNumberFlag && <div style={{marginLeft: '40px', marginTop: '20px', marginBottom: '20px'}}>
                        收据号：<Input placeholder="请输入收据号" style={{width: 180}} value={receiptNumber}
                                   onChange={ReceiptNumber}/>
                    </div>
                }
            </div>
            <div className="line"></div>
            <div className="quittance-btns">
                {detail.recReturn && <Popconfirm
                    title="请确认是否标记已回该收据？"
                    onConfirm={onConfirmHasBeenBack}
                    onCancel={onCancelHasBeenBack}
                    okText="确定"
                    cancelText="取消"
                >
                    <Button type="primary" style={{marginLeft: '20px'}}>已回</Button>
                </Popconfirm>}
                {detail.recCancel && <Popconfirm
                    title="请确认是否核销该收据？"
                    onConfirm={verification}
                    onCancel={verificationCancel}
                    okText="确定"
                    cancelText="取消"
                >
                    <Button type="primary" style={{marginLeft: '20px'}}>核销</Button>
                </Popconfirm>}
                {replenish && <Button onClick={() => leaveOutAudit(3)} className="orange-btn">补充资料</Button>}
                {returnUp && <Button onClick={() => leaveOutAudit(2)} className="orange-btn">转上级</Button>}
                {hasChargerPower && <Button onClick={() => leaveOutAudit(1)}>驳回</Button>}
                {hasChargerPower && detail.recNumberFlag && <Button onClick={() => toReceive()} type="primary">待领取</Button>}
                {hasChargerPower && detail.receiverFlag && <Button onClick={() => leaveOutAudit(0)} type="primary">已领取</Button>}
                {hasChargerPower && !detail.receiverFlag && !detail.recNumberFlag && <Button onClick={() => leaveOutAudit(4)} type="primary">通过</Button>}
                {detail.refundFlag && <Popconfirm
                        title="请确认发起退收据申请"
                        onConfirm={ReturnReceipt}
                        onCancel={ReturnReceiptCancel}
                        okText="提交"
                        cancelText="取消">
                        <Button type="primary" style={{marginLeft: '20px'}}>退收据</Button>
                    </Popconfirm>
                }
            </div>
            <Modal
                title={agreeNode()}
                visible={modalVisible}
                onOk={oprateHandleOk}
                onCancel={oprateHandleCancel}
                destroyOnClose={true}
            >
                <div className="officework-modal-content">
                    <span className="label" style={{width: "100px"}}>审批意见：</span><Input.TextArea
                    className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4}
                    style={{width: "100%"}} maxLength={100}/>
                </div>
            </Modal>
        </Spin>
    )
}

export default Form.create()(Details)