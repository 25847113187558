/**
 * 模块名称: 合同票据以及协议管理
 * @author liujingxue@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Replenish from './Replenish'
import Invoice from './Invoice'
import Contract from './Contract'
import Quittance from './Quittance'
import './assets/style.scss'

const ProtocolIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/replenish'} component={Replenish} />
      <Route path={match.path + '/invoice'} component={Invoice} />
      <Route path={match.path + '/contract'} component={Contract} />
      <Route path={match.path + '/quittance'} component={Quittance} />
      <Route component={Replenish} />
    </Switch>
  )
}

export default ProtocolIndex