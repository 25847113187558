import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Invoice from './Invoice'
import UnInvoiceList from './UnInvoiceList'
import ClearDetail from './ClearDetail'
import Detail from './Detail'
import Modify from './Modify'
import Apply from './Apply'
import '../assets/invoice.scss'
import RejectInvoice from './RejectInvoice'

const InvoiceIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/apply'} exact component={Apply} />
      <Route path={match.path + '/detail'} exact component={Detail} />
      <Route path={match.path + '/rejectInvoice'} exact component={RejectInvoice} />
      <Route path={match.path + '/modify'} exact component={Modify} />
      <Route path={match.path + '/unInvoiceList'} exact component={UnInvoiceList} />
      <Route path={match.path + '/clearDetail'} exact component={ClearDetail} />
      <Route component={Invoice} />
    </Switch>
  )
}

export default InvoiceIndex