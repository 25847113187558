/**
 * 模块名称: 发票管理
 * @author lids@372163.com
 */

import React, { useEffect, useState, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Alert, Button, DatePicker, Drawer, Form, Icon, Input, message, Popconfirm, Radio, Select, Table, Modal, Switch,
  Row, Col
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import CorpFilter from '@/components/CorpFilter'
import BtnGroup from '@/components/BtnGroup'
import Export from '@/components/Export'
import Auth from '@/components/AuthMiddleware'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

const { RangePicker } = DatePicker

let selectedIds = ''

let filter = {
  mainId: ''
}

let params = {}

//筛选初始值
let exporfilter = {
  invoiceNo: '',
  invoiceNumber: '',
  companyId: '',
  invtype: '',
  oaId: '',
  customerName: '',
  collestatus: '',
  stcodetag: '',
  auditStatus: '',
  applyName: '',
  leadCadreName: '',
  departId: '',
  openType: '',
  invtitle: '',
  staffNo: '',
  applyDate: '',
  collectionDate: '',
  openDate: ''
}

const Invoice = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, resetFields, validateFields, setFieldsValue } = props.form
  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [department, setDepartment] = useState([])
  const [expand, setExpand] = useState(false)
  const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)
  const [showExport, setShowExport] = useState(false)
  const [ChannelStatus, setChannelStatus] = useState([])
  const [colleStatusList, setColleStatusList] = useState([])
  // const [collectStatusList, setCollectStatusList] = useState([])
  const [InvoiceStatus, setInvoiceStatus] = useState([])
  const [leaveOutStatus, setLeaveOutStatus] = useState([])
  const [invoiceAddVisible, setInvoiceAddVisible] = useState(false)
  const [moduleLoading, setModuleLoading] = useState(false)
  const [invoiceVenderInfoList, setInvoiceVenderInfoList] = useState([])
  const [venderInfoVal, setVenderInfoVal] = useState('')
  const [radioId, setRadioId] = useState('')
  const [venderData, setVenderData] = useState({})
  const [drawerName, setDrawerName] = useState('')
  const [authList, setAuthList] = useState([])
  const [visibleSave, setVisibleSave] = useState(false)
  const [statusVal, setStatusVal] = useState('请选择')
  const [flag, setFlag] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  const [filterInfoValue, setFilterInfoValue] = useState('')

  const [companyType, setCompanyType] = useState([])
  const [checkedIdArr, setCheckedIdArr] = useState([])
  // const [checkedId, setCheckedId] = useState('')
  const [checked, setChecked] = useState(0)
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    filter = {
      mainId: ''
    }
    initFilter()
    shouldMe = null
    apiOperateTimer = true
    onCommonStaffAlteration()
    getInvoiceList()
    getAuditStatusList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //审批状态
  const getAuditStatusList = () => {
    api.getAuditStatusList({ type: '22,23,24' }).then(data => setLeaveOutStatus(data))
  }

  //初始化filter
  const initFilter = () => {
    exporfilter = {
      invoiceNo: '',
      invoiceNumber: '',
      companyId: '',
      invtype: '',
      oaId: '',
      customerName: '',
      collestatus: '',
      stcodetag: '',
      auditStatus: '',
      applyName: '',
      leadCadreName: '',
      departId: '',
      openType: '',
      invtitle: '',
      staffNo: '',
      applyDate: '',
      collectionDate: '',
      openDate: ''
    }
  }

  //获取列表
  const getFortuneInvoiceList = () => {
    if (apiOperateTimer) {
      setLoading(true)
      let params = {
        limit: pageSize,
        page: currentPage,
        mainId: filter.mainId,
        needAuth: 1
      }
      if (shouldMe) {
        params.shouldMe = shouldMe
      } else {
        for (let i in params) {
          if (i == 'shouldMe') delete params[i]
        }
      }
      api.getFortuneInvoiceList(params).then(data => {
        if (currentPage === 1) {
          if (data.ids) {
            localStorage.setItem('invoiceIds', JSON.stringify(data.ids))
          } else {
            localStorage.setItem('invoiceIds', JSON.stringify([]))
          }
        }
        setList(data.list)
        setCount(data.count)
        setLoading(false)
      }).catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    } else {
      return message.info('请不要频繁操作')
    }
  }
  
  //筛选时调接口
  const getInvoiceList = () => {
    setLoading(true)
    let params = Object.assign({}, exporfilter)
    // console.log(params)
    for (let i in params) {
      if (params[i] === '') delete params[i]
    }
    if (shouldMe) {
      params.shouldMe = shouldMe
    } else {
      for (let i in params) {
        if (i == 'shouldMe') delete params[i]
      }
    }
    api.getFortuneInvoiceList({
      limit: pageSize,
      page: currentPage,
      mainId: filter.mainId,
      ...params,
      needAuth: 1
    }).then(data => {

      setList(data.list)
      if (currentPage === 1) {
        if (data.ids) {
          localStorage.setItem('invoiceIds', JSON.stringify(data.ids))
        } else {
          localStorage.setItem('invoiceIds', JSON.stringify([]))
        }
      }
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const onSetShouldMe = e => {
    if (e == true) {
      shouldMe = 1
    } else {
      shouldMe = null
    }
    getFortuneInvoiceList()
    setCheckedIdArr([])
    setChecked(0)
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    //其他
    api.getFortuneStatusLists().then(data => {
      setInvoiceStatus(data.InvoiceStatus)
      setChannelStatus(data.ChannelStatus)
      setColleStatusList(data.ColleStatus)
      // setCollectStatusList(data.CollectStatus)
    })
    api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartment(data.list)) //部门
    api.getSelectCompanyList({ limit: global.paramsLimit }).then(data => setCompanyType(data.list))
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      // console.log(vals)

      if (!err) {
        currentPage = 1
        history.replace(match.path)
        exporfilter = Object.assign({}, vals)
        for (let i in exporfilter) {
          if (exporfilter[i] === undefined) {
            exporfilter[i] = ''
          }
          if (exporfilter[i] !== undefined && exporfilter[i] !== '' && (i === 'applyDate' || i === 'collectionDate' || i === 'openDate')) {
            exporfilter[i] = exporfilter[i][0].format('YYYY-MM-DD') + '~' + exporfilter[i][1].format('YYYY-MM-DD')
          }
        }
        getInvoiceList()
        setCheckedIdArr([])
        setChecked(0)
      }
    })
  }
  //重置
  const onReset = (e) => {
    e.preventDefault()
    initFilter()
    resetFields()
    shouldMe = null
    currentPage = 1
    history.replace(match.path)
    setStatusVal(undefined)
    setStatusValName(undefined)
    getInvoiceList()
    setCheckedIdArr([])
    setChecked(0)
  }
  //切换收起、展开
  const toggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    if (filter.mainId && filter.mainId.length > 0) {
      if (!Array.isArray(filter.mainId)) {
        filter.mainId = filter.mainId.split(',')
      }
    } else {
      filter.mainId = []
    }
    Object.assign(exporfilter, filter)
    // console.log(exporfilter)
  }

  //筛选
  const onCorpFilter = (data) => {
    // setDepartment([])
    // setFieldsValue({ 'departId': undefined })
    filter.mainId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getFortuneInvoiceList()
    setCheckedIdArr([])
    setChecked(0)
    // api.getSelectDepartmentList({
    //   limit: global.paramsLimit,
    //   companyId: filter.mainId
    // }).then(data => setDepartment(data.list))
  }

  //选部门
  // const getDepartmentId = () => {
  //   if (department == []) {
  //     api.getSelectDepartmentList({ limit: global.paramsLimit, companyId: filter.mainId }).then((data) => {
  //       // console.log(data.list);
  //       data.count > 0 && setDepartment(data.list)
  //     }) //部门
  //   }
  // }
  //审批状态
  const getLeaveOutStatus = () => {
    getAuditStatusList()
  }

  //点击分页
  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getInvoiceList()
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      // setCheckedId(selectedRowKeys.join(","))
      setChecked(selectedRowKeys.length)
      //setCheckedId(checkedIdArr.join(","))
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      //let arr = []
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
      // setChecked(arr.length)
      // selectedIds = arr
    },
    selectedRowKeys: checkedIdArr
  }
  //反选
  const onReverse = () => {
    let IDS = JSON.parse(localStorage.getItem('invoiceIds'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    let filterIds = []
    arr.forEach(item => {
      if (!checkedIdArr.includes(item)) {
        filterIds.push(item)
      }
    })
    setCheckedIdArr(filterIds)
    setChecked(filterIds.length)
    selectedIds = filterIds
  }
  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('invoiceIds'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    //console.log(arr)
    selectedIds = arr
  }
  //删除发票
  const onFortuneInvoiceCtlDel = id => {
    api.onFortuneInvoiceCtlDel({ id }).then(() => {
      message.success('删除成功')
      getInvoiceList()
    }).catch(err => console.log(err))
  }
  //根据厂商id获取详情
  const getFortuneInvoiceVendorInfo = () => {
    setModuleLoading(true)
    if (venderInfoVal == '') {
      setModuleLoading(false)
      return message.warning('请输入厂商ID')
    }
    api.getFortuneInvoiceVendorInfo({ id: venderInfoVal }).then(data => {
      if (JSON.stringify(data.list) === "[]") {
        message.info('该厂商ID不存在')
      } else {
        setInvoiceVenderInfoList(data.list)
        setVenderInfoVal('')
      }
      setModuleLoading(false)
    }).catch(() => {
      setModuleLoading(false)
    })
  }
  const onSearchVenderInfo = (e) => {
    // console.log(e.target.value)
    setVenderInfoVal(e.target.value)
  }
  //
  const getVenderId = (e) => {
    setRadioId(e.target.value)
    for (let i = 0; i < invoiceVenderInfoList.length; i++) {
      if (invoiceVenderInfoList[i].id === e.target.value) {
        setVenderData(invoiceVenderInfoList[i])
      }
    }
  }

  //跳转链接
  const jump = (text, detail = 0) => {
    //详情
    let url = `/protocol/invoice/detail?id=${text.id}&OAID=${text.customerId}&applyId=${text.applyId}&time=${+new Date()}&sign=${CryptoJS.MD5(text.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`

    let title = detail != 0 ? text.invoiceNo : '编辑'

    if (text.editFlag && detail === 0) {
      if (text.rejectType == 2) {
        //冲红驳回重提
        url = `/protocol/invoice/modify?id=${text.id}&OAID=${text.customerId}&applyId=${text.applyId}&editId=${text.id}`
      } else if (text.rejectType == 3) {
        //退票驳回重提
        url = `/protocol/invoice/rejectInvoice?id=${text.id}&OAID=${text.customerId}&applyId=${text.applyId}&editId=${text.id}`
      } else {
        //发票驳回重提
        return (<span className="invoice-form-btn" onClick={() => {
          if (text.clientsouce == 0) {
            history.push('/protocol/invoice/apply', { oaId: text.customerId, id: text.id })
          } else {
            history.push('/protocol/invoice/apply', { qId: text.customerId, id: text.id })
          }

        }}>{title}</span>)

      }
    }
    return (<Link to={url} style={{ cursor: 'pointer' }} target="_blank">{title}</Link>)
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content
    setFieldsValue({
      invoiceNo: filterCont.invoiceNo || filterCont.invoiceNo == 0 ? filterCont.invoiceNo : undefined,//eslint-disable-line
      invoiceNumber: filterCont.invoiceNumber || filterCont.invoiceNumber == 0 ? filterCont.invoiceNumber : undefined,//eslint-disable-line
      companyId: filterCont.companyId || filterCont.companyId == 0 ? filterCont.companyId : undefined,//eslint-disable-line
      invtype: filterCont.invtype || filterCont.invtype == 0 ? filterCont.invtype : undefined,//eslint-disable-line
      oaId: filterCont.oaId || filterCont.oaId == 0 ? filterCont.oaId : undefined,//eslint-disable-line
      customerName: filterCont.customerName || filterCont.customerName == 0 ? filterCont.customerName : undefined,//eslint-disable-line
      collestatus: filterCont.collestatus || filterCont.collestatus == 0 ? filterCont.collestatus : undefined,//eslint-disable-line
      stcodetag: filterCont.stcodetag || filterCont.stcodetag == 0 ? filterCont.stcodetag : undefined,
      auditStatus: filterCont.auditStatus || filterCont.auditStatus == 0 ? filterCont.auditStatus : undefined,
      applyName: filterCont.applyName || filterCont.applyName == 0 ? filterCont.applyName : undefined,
      leadCadreName: filterCont.leadCadreName || filterCont.leadCadreName == 0 ? filterCont.leadCadreName : undefined,
      departId: filterCont.departId || filterCont.departId == 0 ? filterCont.departId : undefined,
      openType: filterCont.openType || filterCont.openType == 0 ? filterCont.openType : undefined,
      invtitle: filterCont.invtitle || filterCont.invtitle == 0 ? filterCont.invtitle : undefined,
      staffNo: filterCont.staffNo || filterCont.staffNo == 0 ? filterCont.staffNo : undefined,
      applyDate: filterCont.applyDate || filterCont.applyDate == 0 ? [moment(filterCont.applyDate[0]), moment(filterCont.applyDate[1])] : undefined,//eslint-disable-line
      collectionDate: filterCont.collectionDate || filterCont.collectionDate == 0 ? [moment(filterCont.collectionDate[0]), moment(filterCont.collectionDate[1])] : undefined,//eslint-disable-line
      openDate: filterCont.openDate || filterCont.openDate == 0 ? [moment(filterCont.openDate[0]), moment(filterCont.openDate[1])] : undefined //eslint-disable-line
    })//选择保存筛选下拉框中的内容

  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'time') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else if (k === 'month') {
                // console.log(typeof (moment(vals[k]).format('YYYY-MM')))
                params[k] = moment(vals[k]).format('YYYY-MM')
              } else {
                params[k] = vals[k]
              }

            }
          }
          setVisibleSave(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisibleSave(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisibleSave(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onCorpFilter} />
      <h4 className="title">其它选项：</h4>
      <Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={6}>
                <FormItem label="业务编号">
                  {getFieldDecorator('invoiceNo')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="发票号">
                  {getFieldDecorator('invoiceNumber')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="申请人主体">
                  {getFieldDecorator('companyId')(<Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                    {
                      companyType.map((v, i) => {
                        return <Option value={v.id} key={i}>{v.name}</Option>
                      })
                    }
                  </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="发票类型">
                  {getFieldDecorator('invtype')(
                    <Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                      <Option value={1} key={1}>纸质普票</Option>
                      <Option value={2} key={2}>纸质专票</Option>
                      <Option value={3} key={3}>电子普票</Option>
                      <Option value={4} key={4}>电子专票</Option>
                    </Select>)
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="OAID">
                  {getFieldDecorator('oaId')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="客户名称">
                  {getFieldDecorator('customerName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="收款状态">
                  {getFieldDecorator('collestatus')(
                    <Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                      {
                        colleStatusList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="发票状态">
                  {getFieldDecorator('stcodetag')(
                    <Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                      {
                        InvoiceStatus.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="审批状态">
                  {getFieldDecorator('auditStatus')(
                    <Select
                      placeholder="请选择"
                      dropdownMatchSelectWidth={false}
                      onDropdownVisibleChange={bool => bool && getLeaveOutStatus()}
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {
                        leaveOutStatus.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)
                  }
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="申请人">
                  {getFieldDecorator('applyName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="负责人">
                  {getFieldDecorator('leadCadreName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="负责人部门">
                  {getFieldDecorator('departId')(
                    <Select
                      placeholder="请选择"
                      dropdownMatchSelectWidth={false}
                      // onDropdownVisibleChange={bool => bool && getDepartmentId()}
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {
                        department.map(v => {
                          // return !filter.mainId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                          return <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option>
                        })
                      }
                    </Select>)
                  }
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="申请途径">
                  {getFieldDecorator('openType')(
                    <Select
                      placeholder="请选择"
                      dropdownMatchSelectWidth={false}
                    >
                      {
                        ChannelStatus.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)
                  }
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="发票抬头">
                  {getFieldDecorator('invtitle')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="员工编号">
                  {getFieldDecorator('staffNo')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="申请时间">
                  {getFieldDecorator('applyDate')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={6}>
                <FormItem label="领取时间">
                  {getFieldDecorator('collectionDate')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="开票时间">
                  {getFieldDecorator('openDate')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={toggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            {alterationTextUnfoldPack ? '展开' : '收起'}<Icon type={expand ? 'up' : 'down'} />
          </span>
        </div>
        <div className="search-btns" style={{ paddingBottom: 10 }}>
          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                placeholder="默认请选择"
                style={{ display: 'inline-block', width: '160px' }}
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {
                  filterContent.map((item, index) =>
                    <Option value={index} key={index}>
                      <div className="closeStyBtn">
                        {item.name}
                        {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                          e.stopPropagation()
                          onSearchDelete(item.id)
                        }} /></span> : null}
                      </div>
                    </Option>
                  )
                }
              </Select>
            </div>

            <Button className="filter-savebtn" onClick={onSaveFilterTerm} style={{ width: 130, margin: '0 15px' }}>保存筛选条件</Button>
          </div>
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <BtnGroup cancelName="重置" confirmName="筛选" onCancel={onReset} />
        </div>
        <Modal
          title="保存筛选条件"
          visible={visibleSave}
          onOk={HandleOk}
          onCancel={HandleCancel}
        >
          <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
        </Modal>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="export">
          <Button type="primary" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
        <Auth auths={authList} code="invoice-clean">
          <Button type="primary" onClick={() => {
            setInvoiceAddVisible(true)
            setDrawerName('发票清理')
          }} style={{ marginLeft: 15 }}>发票清理</Button>
        </Auth>
        <Auth auths={authList} code="staff-attendance-apply">
          <Button type="primary" onClick={() => {
            setInvoiceAddVisible(true)
            setDrawerName('申请发票')
          }} style={{ marginLeft: 15 }}>申请发票</Button>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          {checked !== 0 &&
            <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
          {checked === 0 && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
          <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }}
            onClick={onAllelection}>全选</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        className="invoice-table"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2800 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="业务编号" key="invoiceNo" fixed="left" width={160} render={(text, record) => (
          jump(text, 1)
        )} />
        <Column title="客户名称/代理商名称" fixed="left" dataIndex="customerName" width={180} />
        <Column title="OAID/QID" dataIndex="customerId" />
        <Column title="客户类型" dataIndex="clientsouceName" />
        <Column title="业务类型" dataIndex="businessName" />
        <Column title="发票金额" dataIndex="money" />
        <Column title="发票主体" dataIndex="mainId" />
        <Column title="发票类型" dataIndex='invtypeName' />
        <Column title="发票抬头" dataIndex="invtitle" />
        <Column title="发票号" dataIndex="invoiceNumber" />
        <Column title="发票状态" dataIndex="stcodetagName" />
        <Column title="审批状态" dataIndex="auditStatusName" />
        <Column title="收款状态" dataIndex="collestatus" />
        <Column title="退票信息" dataIndex="invoiceIsRefund" render={(text, record) => <>{text === 1 ? '已退票' : ''}</>} />
        <Column title="申请人" dataIndex="applyName" />
        <Column title="负责人" dataIndex="leadCadreName" />
        <Column title="员工编号" dataIndex="leadStaffNo" />
        <Column title="分公司" dataIndex="leadCompanyId" />
        <Column title="部门" dataIndex="leadDepatName" />
        <Column title="申请途径" dataIndex="openType" />
        <Column title="申请时间" dataIndex="applyDate" />
        <Column title="开票时间" dataIndex="openDate" />
        <Column title="领取时间" dataIndex="collectionDate" />
        <Column title="领取人" dataIndex="receiverName" />
        <Column title="操作" key="set" fixed="right" width={200} render={(text, record) => (
          <div>
            {text.stcodetagName === '已领取' && text.refundFag && <span className="invoice-form-btn"><Link to={`/protocol/invoice/rejectInvoice?id=${text.id}&OAID=${text.customerId}&applyId=${text.applyId}`}>退票</Link></span>}
            {text.redFlag && <span className="invoice-form-btn"><Link to={`/protocol/invoice/modify?id=${text.id}&OAID=${text.customerId}&applyId=${text.applyId}`}>冲红</Link></span>}
            {text.delFlag && <Popconfirm title="请确认是否删除？" cancelText="取消" okText="确认" onConfirm={() => onFortuneInvoiceCtlDel(text.id)}>
              <span className="invoice-form-btn">删除</span>
            </Popconfirm>}
            {text.editFlag && jump(text)}
          </div>
        )} />
      </Table>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        onConfirm={() => {
        }}
        tplUrl="getInvoiceExportTpl"
        fieldsUrl="getInvoiceTplItems"
        saveUrl="saveInvoiceExportTpls"
        exportUrl="exportInvoice"
        method="getlist"
        parame={{ ExportType: 'invoice.getlist', id: selectedIds, ...exporfilter }}
        type={1}
        cRef={productsRef}
      />
      <Drawer
        title={drawerName}
        width={800}
        onClose={() => setInvoiceAddVisible(false)}
        visible={invoiceAddVisible}
        destroyOnClose={true}
      >
        <div className="invoice-add-search-area">
          <span style={{ marginRight: 10 }}>请选择厂商ID</span>
          <Input placeholder="请输入厂商id" style={{ width: 300, marginRight: 10 }} onChange={onSearchVenderInfo} />
          <Button onClick={getFortuneInvoiceVendorInfo} type="primary">查询</Button>
        </div>
        <Table
          dataSource={invoiceVenderInfoList}
          rowKey="id"
          className="invoice-table"
          loading={moduleLoading}
          pagination={false}
        >
          <Column title="项目" dataIndex="projectName" />
          <Column title="产品" dataIndex="productName" />
          <Column title="OA客户名称" dataIndex="client" />
          <Column title="OAID" dataIndex="clientId" />
          <Column title="厂商ID" dataIndex="vendor" />
          <Column title="" render={(text, record) => {
            return <Radio value={text.id} onChange={getVenderId} checked={radioId == text.id} />
          }} />
        </Table>
        <div style={{ margin: '15px 0' }}>
          {drawerName === '申请发票' && <Button disabled={radioId === ''} onClick={() => {
            venderData.type == 0 ?
              history.push('/protocol/invoice/apply', { oaId: venderData.clientId }) :
              history.push('/protocol/invoice/apply', { qId: venderData.agentId })
          }} type="primary" style={{ float: "right" }}>下一步</Button>}
          {drawerName === '发票清理' && <Button disabled={radioId === ''} onClick={() => {
            history.push('/protocol/invoice/unInvoiceList', { venderData })
          }} type="primary" style={{ float: "right" }}>下一步</Button>}
        </div>
      </Drawer>
    </>
  )
}

export default Form.create()(Invoice)