/**
 * 模块名称: 申请发票
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Form,
	Descriptions,
  Button,
  Input,
  Icon,
  Select,
  message,
  Upload,
  InputNumber,
  Row,
  Col,
  Tooltip
} from 'antd'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'

const dItem = Descriptions.item
const { Option } = Select
const { Column } = Table
const FormItem = Form.Item
let pageInfo = {
  pageSize: 10,
  total: 0,
  current: 1,
  pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
}

const Apply = (props) => {
  const { location, history } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const [loading, setLoading] = useState(false)
  const [propsData, setPropsData] = useState({})
  const [pageId, setPageId] = useState('')
  const [acountList, setAcountListList] = useState([])
  const [colleStatusList, setColleStatusList] = useState([])
  const [colleStatusChecked, setColleStatusChecked] = useState('')
  const [collectStatusList, setCollectStatusList] = useState([])
  const [collectStatusChecked, setCollectStatusChecked] = useState('')
  const [claiMList, setClaiMList] = useState([])
  const [acountCheckedTotal, setAcountCheckedTotal] = useState(0)
  const [invTypeSel, setInvTypeSel] = useState(0)
  const [fortuneUnInvoiceList, setFortuneUnInvoiceList] = useState([])
  const [mainId, setMainId] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const search = parseSearch(props.location.search)
  // const [pageInfo, setPageInfo] = useState({
  //   pageSize: 5,
  //   total: 0,
  //   current: 1,
  // })
  const [fileList, setFileList] = useState([])
  const [fileList2, setFileList2] = useState([])
  const [fileList3, setFileList3] = useState([])
  const [fileList4, setFileList4] = useState([])
  const [fileList5, setFileList5] = useState([])
  const [addressVal, setAddress] = useState('')

  const draggerRef = useRef(null)//营业执照
  const draggerRef2 = useRef(null)//税务登记证
  const draggerRef3 = useRef(null)//一般纳税人证明
  const draggerRef4 = useRef(null)//合同
  const draggerRef5 = useRef(null)//其他
  const [clientsouceVal, setClientsouce] = useState ('')
  const [showHide, setShowHide] = useState(true)

  useEffect(() => {
    if(search.msgId) {
      api.getfortuneInvoiceDetail({
        type: 1,
        id: search.id
      }).then(res => {
        const { customerId, clientsouce } = res
        // if (search.msgId) {
        //   api.setMessageReaded({ ids: [search.msgId] })
        // }
        dealFortuneInvoiceDetail(res)
        setClientsouce(res.clientsouce)
        api.getFortuneUnInvoice({
          OAID: customerId,
          type: clientsouce,
          is_leadCadre: 1
        }).then(data => {
          // setPageInfo({
          //   ...pageInfo,
          //   total: data.counts,
          // })
          pageInfo.total = data.counts
          setFortuneUnInvoiceList(data.list)
        })
        setPageId(customerId)

        api.getCustomerGetInfo({
          type: clientsouce,
          id: customerId
        }).then(res => {
          setPropsData(res)
        })
      })
      getFortuneInvoiceAccount()
      onCommonStaffAlteration()
    } else {
      getFortuneInvoiceAccount()
      getFortuneUnInvoice()
      if((!location.state.oaId && location.state.oaId !== 0) && (!location.state.qId && location.state.qId !== 0)){
        history.replace('/protocol/invoice')
        return
      }
      if(location.state.oaId || location.state.oaId == 0){
        setPageId(location.state.oaId)
      }else if(location.state.qId || location.state.qId == 0){
        setPageId(location.state.qId)
      }else{
        return message.warning('缺少OAID/QID')
      }
      getCustomerGetInfo()
      onCommonStaffAlteration()
      if (location.state.id) {
        api.getfortuneInvoiceDetail({
          type: 1,
          id: location.state.id
        }).then(res => {
          dealFortuneInvoiceDetail(res)
        })
      }
    }
  }, [])

  const dealFortuneInvoiceDetail = res => {
    const { 
      mainId, 
      invtype, 
      invtitle, 
      project,
      invident,
      address,
      invtel,
      invkhbank,
      invaccount,
      collestatus,
      money,
      remark,
      invTypeName,
      colleway,
      colleWayName,
      customerId,
      applyId,
      RenKuan,
      email
    } = res
    const { list, count } = RenKuan
    setMainId(mainId)
    setFieldsValue({
      mainId,
      invtype,
      invtitle,
      project,
      invident,
      address,
      invtel,
      invkhbank,
      invaccount,
      collestatus,
      money,
      remark,
    })
    if(invTypeName == '纸质普票')setInvTypeSel(1)
    if(invTypeName == '纸质专票')setInvTypeSel(2)
    if(invTypeName == '电子普票')setInvTypeSel(3)
    if(invTypeName == '电子专票')setInvTypeSel(4)

    if (invTypeName == '电子普票' || invTypeName == '电子专票') {
      setFieldsValue({
        email
      })
    }

    if (collestatus == 2){
      setColleStatusChecked(collestatus)
      setCollectStatusChecked(colleway)
      setFieldsValue({colleway: colleway})
      api.getNewInvoiceClaim({
        OAID: customerId,
        accountId: mainId,
        leadId: applyId,
        channel: colleway
      }).then(data => {
        setClaiMList(data.list)
      }).catch(() => { })
      let ids = []
      let rowKeys = []
      let total = 0
      if(count && count > 0){
        list.forEach(item => {
          ids.push(item.id)
          rowKeys.push(item.rId)
          total += Number(item.balance)
        })
        setSelectedIds(ids)
        setSelectedRowKeys(rowKeys)
        setAcountCheckedTotal(total.toFixed(2))
      }
    }
    dealFileList(res)
  }

  const dealFileList = (res) => { 
    const {
      buslicense,
      certificate,
      invnprove,
      invcontract,
      invothers,
    } = res
    buslicense.forEach((item, index) => {
      fileList.push({
        status: 'done',
        uid: index,
        name: item.name,
        url: item.url
      })
    })
    certificate.forEach((item, index) => {
      fileList2.push({
        status: 'done',
        uid: index,
        name: item.name,
        url: item.url
      })
    })
    invnprove.forEach((item, index) => {
      fileList3.push({
        status: 'done',
        uid: index,
        name: item.name,
        url: item.url
      })
    })
    invcontract.forEach((item, index) => {
      fileList4.push({
        status: 'done',
        uid: index,
        name: item.name,
        url: item.url
      })
    })
    invothers.forEach((item, index) => {
      fileList5.push({
        status: 'done',
        uid: index,
        name: item.name,
        url: item.url
      })
    })
    setFileList(buslicense.map((item, index) => ({...item, uid: index})))
    setFileList2(certificate.map((item, index) => ({...item, uid: index})))
    setFileList3(invnprove.map((item, index) => ({...item, uid: index})))
    setFileList4(invcontract.map((item, index) => ({...item, uid: index})))
    setFileList5(invothers.map((item, index) => ({...item, uid: index})))
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    //收款状态和收款途径
    api.getFortuneStatusLists().then(data => {
      let ColleStatusData = data.ColleStatus.filter((item => item.id !== 3))
      setColleStatusList(ColleStatusData)
      setCollectStatusList(data.CollectStatus)
    })
  }

  //获取直销/代理商详情信息
  const getCustomerGetInfo = () => { 
    api.getCustomerGetInfo({
      type: location.state.oaId ? 0 : 1,
      id: location.state.oaId ? location.state.oaId : location.state.qId
    }).then(res => {
      setPropsData(res)
    })
  }

  //获取开户账户列表
  const getFortuneInvoiceAccount = () => {
    api.getFortuneInvoiceAccount().then(data => {
      setAcountListList(data)
    })
  }

  //获取未收款发票列表
  const getFortuneUnInvoice = () => {
    api.getFortuneUnInvoice({
      OAID: location.state.oaId ? location.state.oaId : location.state.qId,
      type: location.state.oaId ? 0 : 1,
      is_leadCadre: 1
    }).then(data => {
      // setPageInfo({
      //   ...pageInfo,
      //   total: data.counts,
      // })
      pageInfo.total = data.counts
      setFortuneUnInvoiceList(data.list)
    })
  }
  
  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png',
		allowSizeType: 4
  })

  const uploadFiles2 = upLoadModule({
		fileList: fileList2,
		setFileList: setFileList2,
		draggerRef: draggerRef2,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png',
		allowSizeType: 4
  })
  
  const uploadFiles3 = upLoadModule({
		fileList: fileList3,
		setFileList: setFileList3,
		draggerRef: draggerRef3,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png',
		allowSizeType: 4
  })
  
  const uploadFiles4 = upLoadModule({
		fileList: fileList4,
		setFileList: setFileList4,
		draggerRef: draggerRef4,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png',
		allowSizeType: 2,
    limitNum: 20
  })
  
  const uploadFiles5 = upLoadModule({
		fileList: fileList5,
		setFileList: setFileList5,
		draggerRef: draggerRef5,
		accept: '.rar, .zip, .doc, .docx, .pdf, .jpg, .png',
		allowSizeType: 4
	})
  
  //获取收款状态
  const getColleStatus = e => {
    setColleStatusChecked(e)
    setClaiMList([])
    setAcountCheckedTotal(0)
  }
  //获取收款途径
  const getColleway = channel => {
    setSelectedRowKeys([])
    setSelectedIds([])
    setCollectStatusChecked(channel)
    getFortuneInvoiceClaimList(channel)
    setAcountCheckedTotal(0)
  }

  //收款列表
  const getFortuneInvoiceClaimList = (channel) => {
    api.getNewInvoiceClaim({
      OAID: pageId,
      accountId: mainId,
      leadId: propsData.creatorId,
      channel
    }).then(data => {
      setClaiMList(data.list)
    }).catch(res => { })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
      setAcountCheckedTotal(0)
      let total = 0
      let ids = []
      if(selectedRows.length > 0){
        selectedRows.forEach(item => {
          total += Number(item.balance)
          ids.push(item.id)
        })
        setSelectedIds(ids)
        setAcountCheckedTotal(total.toFixed(2))
      }
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys,
  }

  const onSelInvtype = val => {
    setShowHide(false)
    setInvTypeSel(val)
    if (location.state.oaId) {
      getInvoiceGetLastInvoiceData(location.state.oaId, 0, val)
    } 
    if (location.state.qId) {
      getInvoiceGetLastInvoiceData(location.state.qId, 1, val)
    }
  }

  const getInvoiceGetLastInvoiceData = (clientId, clientType, invType) => {
    api.getInvoiceGetLastInvoiceData({ clientId: clientId, clientType: clientType, invType: invType }).then(res => {
      if (!Array.isArray(res)) {
        const buslicenseArr = JSON.parse(res.buslicense) //	营业执照
        const certificateArr = JSON.parse(res.certificate) //	税务登记证
        const invnproveArr = JSON.parse(res.invnprove) //	纳税人证明
        const invcontractArr = JSON.parse(res.invcontract) //	合同
        const invothersArr = JSON.parse(res.invothers)

        buslicenseArr.forEach((item, index) => {fileList.push({status: 'done', uid: index, name: item.name, url: item.url})})
        certificateArr.forEach((item, index) => {fileList2.push({status: 'done', uid: index, name: item.name, url: item.url})})
        invnproveArr.forEach((item, index) => {fileList3.push({status: 'done', uid: index, name: item.name, url: item.url})})
        invcontractArr.forEach((item, index) => {fileList4.push({status: 'done', uid: index, name: item.name, url: item.url})})
        invothersArr.forEach((item, index) => {fileList5.push({status: 'done', uid: index, name: item.name, url: item.url})})

        setFileList(buslicenseArr.map((item, index) => ({...item, uid: index})))
        setFileList2(certificateArr.map((item, index) => ({...item, uid: index})))
        setFileList3(invnproveArr.map((item, index) => ({...item, uid: index})))
        setFileList4(invcontractArr.map((item, index) => ({...item, uid: index})))
        setFileList5(invothersArr.map((item, index) => ({...item, uid: index})))
        setAddress(res.address)
        setFieldsValue({
          invtitle: res.invtitle,
          project: res.project,
          invident: res.invident,
          address: res.address,
          invaccount: res.invaccount,
          invkhbank: res.invkhbank,
          invtel: res.invtel,
          email: res.email
        })
      } else {
        setFieldsValue({
          invtitle: '',
          project: '',
          invident: '',
          address: '',
          invaccount: '',
          invkhbank: '',
          invtel: '',
          email: ''
        })
      }
      setShowHide(true)
    })
  }

  const formSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (err) {
				let errArr = Object.values(err)
				if (errArr.length) {
					return message.error(errArr[0].errors[0].message)
				}
			}
      let params = {}
      if(vals !== undefined){
        params = {
          ...Object.assign({}, vals), 
          // clientsouce: location.state.oaId ? 0 : 1,  // 首页跳转时取不到值
          clientsouce: search.msgId ? clientsouceVal === 0 ? 0 : 1 : location.state.oaId ? 0 : 1,
          customerId: propsData.id, 
          customerName: propsData.name,
          buslicense: fileList,
          certificate: fileList2,
          invnprove: fileList3,
          invcontract: fileList4,
          invothers: fileList5,
          selectBalance: acountCheckedTotal,
          openType: 1,
          rechargenum: selectedIds,
          id: search.msgId ? +search.id : location.state.id
        }

        for(let i in params){
          if(params[i] === undefined)params[i] = ''
        }

        if(claiMList.length === 0 && params.collestatus == 2){
          return message.error('已收款列表为空')
        }
  
        if(selectedIds.length === 0 && params.collestatus == 2)
        { 
          return message.error('请选择收款信息');
        }

        //营业执照和一般纳税人证明
        if(invTypeSel == 2 || invTypeSel == 4){
          if(!params.buslicense.length){
            return message.error('请上传营业执照')
          }
          if(!params.invnprove.length){
            return message.error('请上传一般纳税人证明')
          }
        }
      }
      if(!err){
        setLoading(true)
        api.onFortuneInvoiceApply(params).then(res => {
          message.success('申请已提交~')
          history.replace('/protocol/invoice')
          setLoading(false)
        }).catch(err => setLoading(false))
      }
    })
  }

  const onMainId = (val) => {
    setFieldsValue({
      collestatus: undefined,
      colleway: undefined,
      money: undefined,
      invtype: undefined, // 北京太古时代网络技术有限公司 可选4种类型
    })
    setColleStatusChecked('')
    setClaiMList([])
    // setFortuneUnInvoiceList([])
    setMainId(val)
  }

  const onChangeTable = pagination => {
    const { pageSize, current } = pagination
    // setPageInfo({
    //   ...pageInfo,
    //   pageSize,
    //   current,
    // })
    pageInfo.current = current
    pageInfo.pageSize = pageSize
    getFortuneUnInvoice()
  }

  const onChangeAddress = (e) => {
    setAddress(e.target.value)
  }

  return (
    <div className="add-newinvoice-detail">
      <Form onSubmit={formSubmit}>
      <div className="ask-info">
        <div className="record-list-title">客户信息</div>
          <Row gutter={24}>
            <Col span={5}>
              <FormItem label="OAID/QID">
                {getFieldDecorator('customerId')(<>{pageId}</>)}
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="客户名称/代理商名称">
                {/* {getFieldDecorator('companyName')(<>{propsData.companyName}</>)} */}
                <>{propsData.name}</>
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem label="申请人">
                {/* {getFieldDecorator('creatorName')(<>{propsData.creatorName}</>)} */}
                <>{propsData.principalName}</>
              </FormItem>
            </Col>
            <Col span={7}>
              <FormItem label="开票账户">
                {getFieldDecorator('mainId', {
                  rules: [{ required: true, message: '开票账户不能为空' }]
                })(
                  <Select placeholder="请选择" style={{ width: 180 }} dropdownMatchSelectWidth={false} onSelect={onMainId}>
                    {
                      acountList.map((v, i) => {
                        return <Option value={v.id} key={i}>{v.fullName}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
          </Row>
          <FormItem label="发票类型">
            {getFieldDecorator('invtype', {
              rules: [{ required: true, message: '发票类型不能为空' }]
            })(
              <Select placeholder="请选择" style={{ width: 180 }} dropdownMatchSelectWidth={false} onChange={onSelInvtype}>
                <Option value={1} key={1}>纸质普票</Option>
                <Option value={2} key={2}>纸质专票</Option>
                <Option value={3} key={3}>电子普票</Option>
                <Option value={4} key={4}>电子专票</Option>
              </Select>)
            }
          </FormItem>
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">发票信息</div>
        <Row gutter={24}>
          <Col span={6}>
            <FormItem label="发票抬头">
              {getFieldDecorator('invtitle', {
                rules: [{ required: true, message: '请输入发票抬头' }]})
              (<Input placeholder="请输入" style={{width: 220}} />)}
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="开票项目（服务费或广告费）">
              {getFieldDecorator('project', {
                rules: [{ required: true, message: '请输入开票项目' }]})
              (<Input placeholder="请输入" style={{width: 220}} />)}
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="纳税人识别号">
              {getFieldDecorator('invident', {
                rules: [{ required: true, message: '请输入纳税人识别号' }]})
              (<Input placeholder="请输入" style={{width: 220}} />)}
            </FormItem>
          </Col>
          <Col span={6}>
            <Tooltip
              trigger={['hover']}
              title={addressVal}
              placement="bottomLeft"
              overlayClassName="numeric-input"
            >
              <FormItem label="地址">
                {getFieldDecorator('address', {
                  rules: [{ required: invTypeSel === 2 || invTypeSel === 4 ? true : false, message: '请输入地址' }]})
                (<Input placeholder="请输入" style={{width: 220}} onChange={onChangeAddress}/>)}
              </FormItem>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <FormItem label="电话">
              {getFieldDecorator('invtel', {
                rules: [{ required: invTypeSel === 2 || invTypeSel === 4 ? true : false, message: '请输入电话' }]})
              (<Input placeholder="请输入" style={{width: 220}} />)}
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="开户银行">
            {getFieldDecorator('invkhbank', {
              rules: [{ required: invTypeSel === 2 || invTypeSel === 4 ? true : false, message: '请输入开户银行' }]})
            (<Input placeholder="请输入" style={{width: 220}} />)}
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="开户银行账号">
              {getFieldDecorator('invaccount', {
                rules: [{ required: invTypeSel === 2 || invTypeSel === 4 ? true : false, message: '请输入开户银行账号' }]})
              (<Input placeholder="请输入" style={{width: 220}} />)}
            </FormItem>
          </Col>
          {
            (invTypeSel === 3 || invTypeSel === 4) &&
            <Col span={6}>
              <FormItem label="发票收件人电子邮箱">
                {getFieldDecorator('email', {
                  // rules: [{ required: true, message: '请输入发票收件人电子邮箱' }]
                  rules: [
                    { required: true, message: '请输入发票收件人电子邮箱' },
                    {
                      validator(rule, value, callback) {
                        const errors = []
                        // /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-]+\.)+([A-Za-z]{2,4})$/
                        if (value && !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
                          callback('请输入正确的邮箱格式')
                        } else {
                          callback(errors)
                        }
                      }
                    }
                  ],
                  validateTrigger: 'onBlur'
                })
                  (<Input
                    placeholder="请输入"
                    style={{ width: 220 }}
                  />)}
              </FormItem>
            </Col>
          }
        </Row>
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">附件信息</div>
        {showHide && <Descriptions layout="vertical" column={4} className="pro-detail-title" style={{borderBottom: 'none'}}>
          <dItem label={invTypeSel === 2 || invTypeSel === 4 ? <><span className="required-label">*</span> 营业执照</> : '营业执照'}>
            <div className="dragger-box" ref={draggerRef}>
              <Upload
                {...uploadFiles}
              >
                <Button>
                  <Icon type="upload" /> 上传文件
                </Button>
              </Upload>
            </div>
          </dItem>
          <dItem label="税务登记证">
            <div className="dragger-box" ref={draggerRef2}>
              <Upload
                {...uploadFiles2}
              >
                <Button>
                  <Icon type="upload" /> 上传文件
                </Button>
              </Upload>
            </div>
          </dItem>
          <dItem label={invTypeSel === 2 || invTypeSel === 4 ? <><span className="required-label">*</span> 一般纳税人证明</> : '一般纳税人证明'}>
            <div className="dragger-box" ref={draggerRef3}>
              <Upload
                {...uploadFiles3}
              >
                <Button>
                  <Icon type="upload" /> 上传文件
                </Button>
              </Upload>
            </div>
          </dItem>
          <dItem label="合同">
            <div className="dragger-box" ref={draggerRef4}>
              <Upload
                {...uploadFiles4}
              >
                <Button>
                  <Icon type="upload" /> 上传文件
                </Button>
              </Upload>
            </div>
          </dItem>
          <dItem label="其他">
            <div className="dragger-box" ref={draggerRef5}>
              <Upload
                {...uploadFiles5}
              >
                <Button>
                  <Icon type="upload" /> 上传文件
                </Button>
              </Upload>
            </div>
          </dItem>
        </Descriptions>}
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">收款信息</div>
        <FormItem label="收款状态">
          {getFieldDecorator('collestatus', {
            rules: [{ required: true, message: '请选择收款状态' }]
          })(
            <Select placeholder="请选择" style={{ width: 139 }} dropdownMatchSelectWidth={false} onChange={getColleStatus}>
            {
              colleStatusList.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>)}
        </FormItem>
        {colleStatusChecked === 2 && <FormItem label={mainId ? "收款途径" : "收款途径（请先选择开票账户）"}>
          {getFieldDecorator('colleway', {
            rules: [{ required: colleStatusChecked === 2 ? true : false, message: '请选择收款途径' }]
          })(
            <Select disabled={!mainId} placeholder="请选择" style={{ width: 139 }} dropdownMatchSelectWidth={false} onChange={getColleway}>
            {
              collectStatusList.map((v, i) => {
                return <Option value={v.id} key={i}>{v.name}</Option>
              })
            }
          </Select>)}
        </FormItem>}
        <FormItem label="开票金额">
          {getFieldDecorator('money', {
            // rules: [{ required: colleStatusChecked === 2 ? true : false, message: '请输入金额' }]})
            rules: [{ required: true, message: '请输入金额' }]})
          (<InputNumber placeholder="请输入" style={{width: 180}} min={0.01} step={0.01} precision={2}/>)}
        </FormItem>
        {colleStatusChecked === 2 && collectStatusChecked === 1 && <Table
          dataSource={claiMList}
          rowKey="rId"
          className="today-info"
          pagination={false}
          scroll={{ y: 300 }}
          rowSelection={rowSelection}
        >
          <Column title="客户名称/代理商名称" dataIndex="customerName" />
          <Column title="OAID/QID" dataIndex="OAID" />
          <Column title="账户" dataIndex="accountName" />
          <Column title="类型" dataIndex="type" />
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="付款方户名" dataIndex="payName" />
          {/* <Column title="业务ID" dataIndex="rId" /> */}
          <Column title="收款" dataIndex="collect" />
          <Column title="发票额度支出" dataIndex="pay" />
          <Column title="发票余额" dataIndex="balance" />
          <Column title="备注" dataIndex="remark" />
          <Column title="负责人" dataIndex="operateName" />
          <Column title="操作时间" dataIndex="operateTime" />
        </Table>}
        {colleStatusChecked === 2 && collectStatusChecked === 2 && <Table
          dataSource={claiMList}
          rowKey="rId"
          className="today-info"
          pagination={false}
          scroll={{ y: 300 }}
          rowSelection={rowSelection}
        >
          <Column title="客户名称/代理商名称" dataIndex="customerName" />
          <Column title="OAID/QID" dataIndex="OAID" />
          <Column title="账户" dataIndex="accountName" />
          <Column title="业务编号" dataIndex="businessNo" />
          <Column title="市场价" dataIndex="market" />
          <Column title="实收" dataIndex="real" />
          <Column title="发票余额" dataIndex="balance" />
          <Column title="操作人" dataIndex="operateName" />
          <Column title="操作时间" dataIndex="operateTime" />
        </Table>}
        {colleStatusChecked === 2 && collectStatusChecked !== '' && <div className="acount-checked-total">
          已选中发票余额 <span style={{color: 'red'}}>{acountCheckedTotal}</span>
        </div>}
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">其他信息</div>
        <div className="record-list-title" style={{borderBottom: 'none', fontSize: '16px'}}>未收款发票：</div>
        <Table
          dataSource={fortuneUnInvoiceList}
          rowKey="id"  
          className="fortuneUnInvoiceList-info"
          pagination={pageInfo}
          onChange={onChangeTable}
        >
          <Column title="序号" dataIndex="id" />
          <Column title="业务编号" dataIndex="invoiceNo" />
          <Column title="客户名称" dataIndex="customerName" />
          <Column title="金额" dataIndex="money" />
          <Column title="发票类型" dataIndex="invtype" />
          <Column title="发票号" dataIndex="invoiceNumber" />
          <Column title="申请人" dataIndex="applyName"/>
          <Column title="发票状态" dataIndex="stcodetag" />
        </Table>
        <div className="record-list-title" style={{borderBottom: 'none', fontSize: '16px', marginTop: '30px', marginBottom: 0}}>备注：</div>
        <div>
          {getFieldDecorator('remark')(<Input.TextArea placeholder="请输入" autosize={{minRows: 3, maxRows: 6}}></Input.TextArea>)}
        </div>
      </div>
      <div className="blank-line"></div>
      <div className="task-bottom-btns">
        <Button className="operate-icon" size="default" onClick={() => {
          history.replace('/protocol/invoice')
        }}>取消</Button>
        <Button className="operate-icon" type="primary" size="default" htmlType="submit" loading={loading}>提交</Button>
      </div>
      </Form>
    </div>
  )
}
export default Form.create()(Apply)