import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Replenish from './Replenish'
import Details from './Details'
import Apply from './Apply'
import Execute from './Execute'
import Examine from './Examine'

const ReplenishIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/details'} component={Details} />
      <Route path={match.path + '/apply'} component={Apply} />
      <Route path={match.path + '/execute'} component={Execute} />
      <Route path={match.path + '/examine'} component={Examine} />
      <Route component={Replenish} />
    </Switch>
  )
}

export default ReplenishIndex