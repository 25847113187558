/**
 * 模块名称: 收据管理列表
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  DatePicker,
  Select,
  message,
  Alert,
  Popconfirm,
  Drawer,
  Spin,
  Upload,
  Modal,
  Switch,
  Row,
  Col
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import BtnGroup from '@/components/BtnGroup'
import Export from '@/components/Export'
import CryptoJS from 'crypto-js'

const { Dragger } = Upload
const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

const { RangePicker } = DatePicker

let selectedIds = ''

//所属公司筛选
let filter = {
  companyId: ''
}

let params = {}

//筛选初始值
let exporfilter = {
  businessNo: '',
  customerId: '',
  customerName: '',
  receiptNo: '',
  status: '',
  collectStatus: '',
  applyName: '',
  receiver: '',
  departId: '',
  staffNo: '',
  receiveDateMin: '',
  receiveDateMax: ''
}

const Quittance = (props) => {

  const { match, location, history } = props
  const { getFieldDecorator, resetFields, validateFields, setFieldsValue } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [department, setDepartment] = useState([])
  const [expand, setExpand] = useState(false)
  const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)
  const [showExport, setShowExport] = useState(false)
  const [checked, setChecked] = useState(0)
  const [execStatus, setExecStatus] = useState([])
  const [visible, setVisible] = useState(false)
  const [visibleSave, setVisibleSave] = useState(false)
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [execid, setExecid] = useState('')
  const [orderForm, setorderForm] = useState('')
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [ids, setIds] = useState([])
  // const [checkedId, setCheckedId] = useState('')
  const [authList, setAuthList] = useState([])
  const [statusVal, setStatusVal] = useState('请选择')
  const [flag, setFlag] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    filter = {
      companyId: ''
    }
    exporfilter = {
      businessNo: '',
      customerId: '',
      customerName: '',
      receiptNo: '',
      status: '',
      collectStatus: '',
      applyName: '',
      receiver: '',
      departId: '',
      staffNo: '',
      receiveDateMin: '',
      receiveDateMax: ''
    }
    shouldMe = null
    apiOperateTimer = true
    onCommonStaffAlteration()
    getEmployeeList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //获取收据管理列表
  const getFortuneReceiptList = () => {
    if (apiOperateTimer) {
      setLoading(true)
      let params = {
        limit: pageSize,
        page: currentPage,
        companyId: filter.companyId
      }
      if (shouldMe) {
        params.shouldMe = shouldMe
      } else {
        for (let i in params) {
          if (i == 'shouldMe') delete params[i]
        }
      }
      api.getFortuneReceiptList(params).then(data => {
        // console.log('data', data)
        setList(data.list)
        setIds(data.ids)
        if (currentPage === 1) {
          if (data.ids) {
            localStorage.setItem('ids', JSON.stringify(data.ids))
          } else {
            localStorage.setItem('ids', JSON.stringify([]))
          }
        }
        setChecked(0)
        setCheckedIdArr([])
        setCount(data.count)
        setLoading(false)
      }).catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    } else {
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if (e == true) {
      shouldMe = 1
    } else {
      shouldMe = null
    }
    getFortuneReceiptList()
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    api.getFortuneReceiptStatus().then(data => {
      // console.log(data)
      setExecStatus(data.ReceiptStatus)
    })
  }

  //筛选时调接口
  const getEmployeeList = () => {
    // console.log(exporfilter)
    setLoading(true)
    api.getFortuneReceiptList({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      businessNo: exporfilter.businessNo,
      customerId: exporfilter.customerId,
      customerName: exporfilter.customerName,
      receiptNo: exporfilter.receiptNo,
      status: exporfilter.status,
      collectStatus: exporfilter.collectStatus,
      applyName: exporfilter.applyName,
      receiver: exporfilter.receiver,
      departId: exporfilter.departId,
      staffNo: exporfilter.staffNo,
      receiveDate: exporfilter.receiveDateMin == '' ? '' : exporfilter.receiveDateMin + '~' + exporfilter.receiveDateMax
    }).then(data => {
      setList(data.list)
      // setIds(data.ids)
      if (currentPage === 1) {
        if (data.ids) {
          localStorage.setItem('ids', JSON.stringify(data.ids))
        } else {
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      //setChecked(0)
      //setCheckedIdArr([])
      if (data.ids.length > 0) setIds(data.ids)
      // setChecked(0)
      // setCheckedIdArr([])
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //const values = Object.values(vals)
      //console.log(values)
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        exporfilter.businessNo = vals.businessNo || vals.businessNo == 0 ? vals.businessNo : ''//eslint-disable-line
        exporfilter.customerId = vals.customerId || vals.customerId == 0 ? vals.customerId : ''//eslint-disable-line
        exporfilter.customerName = vals.customerName || vals.customerName == 0 ? vals.customerName : ''//eslint-disable-line
        exporfilter.receiptNo = vals.receiptNo || vals.receiptNo == 0 ? vals.receiptNo : ''//eslint-disable-line
        exporfilter.status = vals.status || vals.status == 0 ? vals.status : ''//eslint-disable-line
        exporfilter.collectStatus = vals.collectStatus || vals.collectStatus == 0 ? vals.collectStatus : ''//eslint-disable-line
        exporfilter.applyName = vals.applyName || vals.applyName == 0 ? vals.applyName : ''//eslint-disable-line
        exporfilter.receiver = vals.receiver || vals.receiver == 0 ? vals.receiver : ''//eslint-disable-line
        exporfilter.departId = vals.departId || vals.departId == 0 ? vals.departId : ''//eslint-disable-line
        exporfilter.staffNo = vals.staffNo || vals.staffNo == 0 ? vals.staffNo : ''//eslint-disable-line
        exporfilter.receiveDateMin = vals.receiveDate || vals.receiveDate == 0 ? vals.receiveDate[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.receiveDateMax = vals.receiveDate || vals.receiveDate == 0 ? vals.receiveDate[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        getEmployeeList()
        setChecked(0)
        setCheckedIdArr([])
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter = {
      businessNo: '',
      customerId: '',
      customerName: '',
      receiptNo: '',
      status: '',
      collectStatus: '',
      applyName: '',
      receiver: '',
      departId: '',
      staffNo: '',
      receiveDateMin: '',
      receiveDateMax: ''
    }
    resetFields()
    shouldMe = null
    currentPage = 1
    history.replace(match.path)
    setStatusVal(undefined)
    setStatusValName(undefined)
    getEmployeeList()
    setChecked(0)
    setCheckedIdArr([])
  }

  const toggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    if (filter.companyId && filter.companyId.length > 0) {
      if (!Array.isArray(filter.companyId)) {
        filter.companyId = filter.companyId.split(',')
      }
    } else {
      filter.companyId = []
    }
    Object.assign(exporfilter, filter)
    // console.log(exporfilter)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      // setCheckedId(selectedRowKeys.join(","))
      setChecked(selectedRowKeys.length)
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
      // setChecked(arr.length)
      // selectedIds = arr
    },
    selectedRowKeys: checkedIdArr
  }

  //筛选
  const onCorpFilter = (data) => {
    // setDepartment([])
    // setFieldsValue({ 'departId': undefined })
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getFortuneReceiptList()
  }

  //选中所属公司后部门下拉框内容随之变化
  const onDepartmentId = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartment(data.list)) //部门
  }

  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getEmployeeList()
  }

  const onDelete = (id) => {
    // console.log(id)
    api.setFortuneReceiptCtlDel({ id: id }).then(() => {
      message.success('删除成功')
      getEmployeeList()
    })
  }

  const afterVisibleChange = (isOpen) => {
    if (isOpen) {

    }
  }

  //补充
  const onReplenish = (value) => {
    setVisible(true)
    setExecid(value.execid)
    // console.log(value.execid)
  }

  //取消
  const onRelevance = () => {
    setVisible(false)
  }

  //补充确定按钮
  const onConfirmReplenish = () => {
    setVisible(false)
    // console.log(fileList)
    api.getFortuneSupplyAgreementAddOrder({
      exId: execid,
      orderNo: orderForm,
      status: 5,
      fileData: JSON.stringify(fileList)
    }).then(data => {
      // console.log(data)
    }).catch(error => {
      // console.log(error)
    })
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.gif, .png, .jpg, .jepg, .PDF, .doc, .docx, .csv, .xlsx, .zip',
    allowSizeType: 2
  })

  const onTextArea = (e) => {
    // console.log(e.target.value)
    setorderForm(e.target.value)
  }

  //反选
  const onReverse = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    let filterIds = []
    arr.forEach(item => {
      if (!checkedIdArr.includes(item)) {
        filterIds.push(item)
      }
    })
    setCheckedIdArr(filterIds)
    setChecked(filterIds.length)
    selectedIds = filterIds
  }

  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    //console.log(arr)
    selectedIds = arr
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content
    setFieldsValue({
      businessNo: filterCont.businessNo || filterCont.businessNo == 0 ? filterCont.businessNo : undefined,//eslint-disable-line
      customerId: filterCont.customerId || filterCont.customerId == 0 ? filterCont.customerId : undefined,//eslint-disable-line
      customerName: filterCont.customerName || filterCont.customerName == 0 ? filterCont.customerName : undefined,//eslint-disable-line
      receiptNo: filterCont.receiptNo || filterCont.receiptNo == 0 ? filterCont.receiptNo : undefined,//eslint-disable-line
      status: filterCont.status || filterCont.status == 0 ? filterCont.status : undefined,//eslint-disable-line
      applyName: filterCont.applyName || filterCont.applyName == 0 ? filterCont.applyName : undefined,//eslint-disable-line
      staffNo: filterCont.staffNo || filterCont.staffNo == 0 ? filterCont.staffNo : undefined,//eslint-disable-line
      receiver: filterCont.receiver || filterCont.receiver == 0 ? filterCont.receiver : undefined,//eslint-disable-line
      departId: filterCont.departId || filterCont.departId == 0 ? filterCont.departId : undefined,//eslint-disable-line
      receiveDate: filterCont.receiveDate || filterCont.receiveDate == 0 ? [moment(filterCont.receiveDate[0]), moment(filterCont.receiveDate[1])] : undefined //eslint-disable-line
    })//选择保存筛选下拉框中的内容

  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'time') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else if (k === 'month') {
                // console.log(typeof (moment(vals[k]).format('YYYY-MM')))
                params[k] = moment(vals[k]).format('YYYY-MM')
              } else {
                params[k] = vals[k]
              }

            }
          }
          setVisibleSave(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisibleSave(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisibleSave(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  return (
    <>
      <CorpFilter onChange={onCorpFilter} />
      <h4 className="title">其它选项：</h4>
      <Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo">
        <div className="search-item-wrap">
          <div className="search-item">
            <Row gutter={24}>
              <Col span={8}>
                <FormItem label="业务编码">
                  {getFieldDecorator('businessNo')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="OAID/QID">
                  {getFieldDecorator('customerId')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={10}>
                <FormItem label="(OA客户/代理商)名称" >
                  {getFieldDecorator('customerName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="收据号">
                  {getFieldDecorator('receiptNo')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="业务状态" >
                  {getFieldDecorator('status')(
                    <Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                      {
                        execStatus.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="申请人" >
                  {getFieldDecorator('applyName')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="员工编号" >
                  {getFieldDecorator('staffNo')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="领取人" >
                  {getFieldDecorator('receiver')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="所属部门" >
                  {getFieldDecorator('departId')(
                    <Select
                      placeholder="请选择"
                      dropdownMatchSelectWidth={false}
                      onDropdownVisibleChange={bool => bool && onDepartmentId()}
                      showSearch={true}
                      optionFilterProp="children"
                    >
                      {
                        department.map(v => {
                          // return !filter.companyId.length ? <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option> : <Option title={v.name} key={v.id}>{v.name}</Option>
                          return <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="领取时间" >
                  {getFieldDecorator('receiveDate')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={toggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
            {alterationTextUnfoldPack ? '展开' : '收起'}<Icon type={expand ? 'up' : 'down'} />
          </span>
        </div>
        <div className="search-btns" style={{ paddingBottom: 10 }}>
          <div className={`search-btns ${expand ? '' : 'hide'}`}>
            <h4 className="filter-title">已保存筛选方案：</h4>
            <div className="filter-select">
              <Select
                placeholder="默认请选择"
                style={{ display: 'inline-block', width: '160px' }}
                value={statusVal}
                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                onSelect={onChecked}
                dropdownMatchSelectWidth={false}
              >
                {
                  filterContent.map((item, index) =>
                    <Option value={index} key={index}>
                      <div className="closeStyBtn">
                        {item.name}
                        {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                          e.stopPropagation()
                          onSearchDelete(item.id)
                        }} /></span> : null}
                      </div>
                    </Option>
                  )
                }
              </Select>
            </div>
            <Button className="filter-savebtn" onClick={onSaveFilterTerm} style={{ width: 130, margin: '0 15px' }}>保存筛选条件</Button>
          </div>
          <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
          <BtnGroup cancelName="重置" confirmName="查询" onCancel={onReset} />
        </div>
        <Modal
          title="保存筛选条件"
          visible={visibleSave}
          onOk={HandleOk}
          onCancel={HandleCancel}
        >
          <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
        </Modal>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="export">
          <Button type="primary" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
        <Auth auths={authList} code="staff-attendance-apply">
          <Link to={`${match.path}/apply`} style={{ cursor: 'pointer' }}>
            <Button type="primary" style={{ marginLeft: 15 }}>申请</Button>
          </Link>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          {checked !== 0 && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
          {checked == 0 //eslint-disable-line
            && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
          <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onAllelection}>全选</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1800 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="业务编号" dataIndex="receiptNo" fixed="left" width={180} render={(text, record) => (
          <Link to={`${match.path}/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + (+new Date())).toString()}`} style={{ cursor: 'pointer' }}>{text}</Link>
        )} />
        <Column title="收据号" dataIndex="receiptNumber" width={150} fixed="left" />
        <Column title="收据账户" dataIndex="mainName" width={150} />
        <Column title="OAID/QID" dataIndex="customerId" width={150} />
        <Column title="OA客户名称/代理商名称" dataIndex="customerName" width={180} />
        <Column title="业务状态" dataIndex="busiStatusName" width={150} />
        <Column title="金额" dataIndex="amount" width={150} />
        <Column title="申请人" dataIndex="applyName" width={150} />
        <Column title="员工编号" dataIndex="staffNo" width={150} />
        <Column title="所属部门" dataIndex="departName" width={180} />
        <Column title="领取人" dataIndex="receiverName" width={150} />
        <Column title="申请时间" dataIndex='applyDate' width={200} />
        <Column title="领取时间" dataIndex="receiveDate" width={200} />
        <Column title="返回时间" dataIndex="backDate" width={200} />
        <Column title="操作" key="set" fixed="right" width={150} render={(text, record) => (
          <>
            {
              record.delFlag && record.operateType == 0 && <Popconfirm title="请确认是否删除本条记录，删除后不可见？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }}>删除</span>
              </Popconfirm>
            }
            {
              record.editFlag && record.operateType == 0 && <Link to={`${match.path}/apply?id=${record.id}`} style={{ cursor: 'pointer' }}>
                <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }}>编辑</span>
              </Link>
            }
          </>
        )} />
      </Table>
      <Drawer
        title="补充订单信息"
        width={800}
        onClose={() => setVisible(false)}
        visible={visible}
        afterVisibleChange={afterVisibleChange}
      >
        <Spin>
          <div className="spin">
            <div className="drawer">
              <div>订单编号：</div>
              <div>
                <Input.TextArea placeholder="请输入订单编号" maxLength={100} style={{ width: 500, marginLeft: '70px' }} onChange={onTextArea} />
              </div>
            </div>
            <div style={{ marginTop: '40px' }}>
              <div style={{ paddingLeft: '30px' }} > 附件：</div>
              <div className="dragger-box" ref={draggerRef} style={{ width: 500, marginLeft: '70px' }}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    支持扩展名：GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip、.rar、JEPG 单个文件大小不超过5M，不超过5个附件。
                  </p>
                </Dragger>
              </div>
            </div>
            <div className="replenish-button" >
              <BtnGroup onCancel={onRelevance} onConfirm={onConfirmReplenish} />
            </div>
          </div>
        </Spin>
      </Drawer>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getFortuneReceiptChangeExportTpl"
        fieldsUrl="getFortuneReceiptChangeTplItems"
        saveUrl="saveFortuneReceiptChangeExportTpls"
        exportUrl="exportFortuneReceiptChange"
        method="export"
        parame={{ ExportType: 'receipt.export', id: selectedIds, ...exporfilter }}
        type={1}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(Quittance)