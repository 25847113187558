/**
 * 模块名称: 补充协议信息详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Spin,
  Icon,
  message,
  Steps,
  Button,
  Modal,
  Input,
  InputNumber
} from 'antd'
import api from '@/api'
import { parseSearch } from '@/utils'
import CryptoJS from 'crypto-js'

const { Step } = Steps
const dItem = Descriptions.item

let areaTxt = '', result = ''

const Details = (props) => {

  const { history, location } = props

  const [detail, setDetail] = useState({})
  const [flow, setFlow] = useState([])
  const [remark, setRemark] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [pageId, setPageId] = useState('')
  const [auditRecord, setAuditRecord] = useState([])
  const [loading, setLoading] = useState(true)
  const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
  const [accountValue, setAccountValue] = useState('')
  const [filesData,setFilesData] = useState([])
  const [productsDetail, setProductsDetail] = useState({})

  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

  useEffect(() => {
    let plaintext = parseSearch(location.search).sign
    let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

    if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
      areaTxt = ''
      result = ''
      if (location.search) {
        setPageId(parseSearch(location.search).id)
        getLeaveOutRow(parseSearch(location.search).id)
      } else {
        history.push({
          pathname: '/protocol/replenish'
        })
      }
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  //获取日常办公审批单条的详情
  const getLeaveOutRow = (id) => {
    api.getFortuneSupplyAgreementDetail({ id: id, type: 0 }).then(res => {
      if (parseSearch(location.search).msgId) {
        api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
      }
      if (JSON.stringify(res) !== "[]") {
        let data = Object.assign({}, res)
        if (data.status === 0) data.current = 0
        if (data.status === 1) data.current = 1
        if (data.status === 2) data.current = 2
        if (data.status === 3) data.current = 3
        setDetail(data)
        setAccountValue(data.remind)
        setProductsDetail(JSON.parse(data.productsDetail))
        setFilesData(JSON.parse(data.filesData))
        let auditFlow = data.audit_flow
        auditFlow.forEach((item, index, self) => {
          if (item.persons && item.persons.length) {
            self[index].persons = item.persons.join('，')
          }
        })
        setFlow(auditFlow)
        setAuditRecord(data.audit_log)
        if (data.remark.length) {
          setRemark(data.remark)
        } else {
          setRemark('无')
        }
        if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
          setAuditAuth(true)
          setHasChargerPower(data.auditAuth.hasChargerPower)
          setReplenish(data.auditAuth.replenish)
          setReturnUp(data.auditAuth.return_up)
          setRejectMsgRequire(data.auditAuth.reject_msg_require)
        } else {
          setAuditAuth(false)
          setHasChargerPower(false)
          setReplenish(false)
          setReturnUp(false)
          setRejectMsgRequire(false)
        }
      } else {
        setDetail({})
        setFlow([])
        setAuditRecord([])
      }
      setLoading(false)
    })
  }

  //点击4个审批的按钮
  const leaveOutAudit = (no) => {
    areaTxt = ''
    result = no
    if (no == 0) {//eslint-disable-line
      setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    } else if (no == 1) {//eslint-disable-line
      //审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
      setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    } else {
      api.setFortuneSupplyAgreementAudit({
        id: pageId,
        type: result,
        remark: areaTxt
      }).then(res => {
        //todo 转上级或者补充资料 返回列表页
        let timer = setTimeout(() => {
          history.push({
            pathname: '/protocol/replenish'
          })
          clearTimeout(timer)
        }, 1000)
      })
    }
  }

  const oprateHandleOk = () => {
    if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
      message.warning('请输入至少1个字符')
      return
    }
    let params = {
      id: pageId,
      type: result,
      remark: areaTxt
    }
    // saveAppealAudit
    api.setFortuneSupplyAgreementAudit(params).then(res => {
      message.success('审批成功')
      setModalVisible(false)
      getLeaveOutRow(pageId)
    })
  }

  //取消弹窗
  const oprateHandleCancel = () => setModalVisible(false)

  //同意的样式
  const agreeNode = () => {
    return result === 0 ? <>
      同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </> : <>
        不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
      </>
  }
  //审批意见
  const getAreaTxt = (e) => areaTxt = e.target.value.trim()

  //结算日期
  const onChangeAccount = (value) => setAccountValue(value)

  //修改结算日期
  const onClickAccount = () => {
    if (accountValue == '' || !accountValue ) {
      return message.warning('请输入结算提醒日期')
    }
    setLoading(true)
    api.getFortuneSupplyAgreementSettleRemind({
      id: pageId,
      remind: accountValue
    }).then(() => {
      message.success('修改成功')
      getLeaveOutRow(pageId)
      setLoading(false)
    }).catch(() => setLoading(false))
  }


  return (
    <Spin spinning={loading}>
      <Descriptions
        title={'协议编号：' + detail.agreeNo}
        layout="horizontal"
        className="pro-detail-title"
        style={{ borderBottom: 'none', marginBottom: '5px' }}
      >
        {detail.userName && <dItem label="申请人">{`${detail.userName}（ID：${detail.id}）`}</dItem>}
      </Descriptions>
      <div className="blank-line"></div>
      <div className="step-wrap">
        <div className="record-list-title">当前审批进度</div>
        <Steps current={detail.current}>
          {
            flow.map((item, index, self) => {
              let status = 'wait'
              if (item.time && item.time.length > 1) {
                status = 'process'
              }
              return <Step status={status} title={item.nodeName} key={index} description={
                <>
                  {item.persons && <div title={item.persons}>{item.persons}</div>}
                  <div title={item.statusName}>{item.statusName}</div>
                  <div title={item.time}>{item.time}</div>
                </>
              } />
            })
          }
        </Steps>
      </div>
      <div className="line"></div>
      <Descriptions title="客户信息" layout="vertical" column={4} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
        <dItem label="OAID/QID">{detail.ditch == 0 ? 'OAID：' : 'QID：'}{detail.oaId}</dItem>
        <dItem label={detail.ditch == 0 ? '客户名称' : '代理商名称'}>{detail.oaName}</dItem>
        <dItem label="合同号">{detail.contractno}</dItem>
        <dItem label="合同类型">{detail.templateTypeName}</dItem>
        <dItem label="有效日期">{detail.beginTime} ~ {detail.endTime}</dItem>
        <dItem label="结算提醒日期">
          {
            detail.setDate ? <>
              {detail.remind && <InputNumber min={1} max={28} onChange={onChangeAccount} defaultValue={detail.remind}/>}
              <Button style={{marginLeft: '20px'}} type="primary" size="small" onClick={onClickAccount}>保存</Button>
            </> : <>{detail.remind}</>
          }
        </dItem>
        <dItem label="结算日期" span={2}>{detail.settlementTime}</dItem>
        <dItem label="政策" span={1}>{detail.rule}</dItem>
      </Descriptions>
      <div className="line"></div>
      <Descriptions title="关联项目" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
        <dItem label="关联项目"></dItem>
          {
            productsDetail.length > 0 && productsDetail.map(item => {
              if (item.selected) {
                return <dItem label={item.label} key={item.key}>
                  {item.selected.map(child => <span className="apply-add-project" key={child.key}>{child.label}</span>)}
                </dItem>
              } else {
                return <dItem label="" key={item.key}><span className="apply-add-project">{item.label}</span></dItem>
              }
            })
          }
      </Descriptions>
      <div className="line"></div>
      <Descriptions title="其他信息" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
        <dItem label="附件">
          {
            Array.isArray(filesData) ? filesData.map((v,i) => <a href={v.url} key={i} target="_blank">{v.name}</a>) : <div>{detail.filesData}</div>
          }
        </dItem>
        <dItem label="附件说明">{detail.describe}</dItem>
        <dItem label="备注">{remark}</dItem>
      </Descriptions>
      <div className="line"></div>
      <div className="record-list" style={{ marginTop: '10px', marginBottom: '14px' }}>
        <div className="record-list-title">审批记录</div>
        <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
          <dItem label="姓名"></dItem>
          <dItem label="时间"></dItem>
          <dItem label="状态"></dItem>
          <dItem label="审批意见"></dItem>
        </Descriptions>
        {
          auditRecord.map((item, index) => {
            return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
              <dItem label="">{item.userInfo.staffName}</dItem>
              <dItem label="">{item.addTime}</dItem>
              <dItem label="">{item.status}</dItem>
              <dItem label="">{item.chargeMsg}</dItem>
            </Descriptions>
          })
        }
      </div>
      <div className="line"></div>
      <div className="replenish-btns">
        {auditAuth && <div className="replenish-btns-child">
          {replenish && <Button onClick={() => leaveOutAudit(3)} className="orange-btn">补充资料</Button>}
          {returnUp && <Button onClick={() => leaveOutAudit(2)} className="orange-btn">转上级</Button>}
          {hasChargerPower && <Button onClick={() => leaveOutAudit(1)}>驳回</Button>}
          {hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary">通过</Button>}
        </div>}
        <Modal
          title={agreeNode()}
          visible={modalVisible}
          onOk={oprateHandleOk}
          onCancel={oprateHandleCancel}
          destroyOnClose={true}
        >
          <div className="officework-modal-content">
            <span className="label">意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
          </div>
        </Modal>
      </div>
    </Spin>
  )
}

export default Details