/**
 * 模块名称: 补充协议管理
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  DatePicker,
  Select,
  message,
  Alert,
  Popconfirm,
  Drawer,
  Upload,
  Row,
  Col,
  Modal
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import BtnGroup from '@/components/BtnGroup'
import Export from '@/components/Export'
import CryptoJS from 'crypto-js'

const { Dragger } = Upload
const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
const { RangePicker } = DatePicker

let selectedIds = ''

//所属公司筛选
let filter = {
  companyId: ''
}

let params = {}

//筛选初始值
let exporfilter = {
  oaId: '',
  ditch: '',
  name: '',
  agreeNo: '',
  applyName: '',
  applyStatus: '',
  executeStatus: '',
  rule: '',
  productsId: '',
  dateMin: '',
  dateMax: '',
  remindMin: '',
  remindMax: ''
}

const Replenish = (props) => {
  const { match, location, history } = props
  const { getFieldDecorator, resetFields, validateFields, setFieldsValue } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [expand, setExpand] = useState(false)
  const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)
  const [showExport, setShowExport] = useState(false)
  const [checked, setChecked] = useState(0)
  const [execStatus, setExecStatus] = useState([])
  const [supplyStatus, setSupplyStatus] = useState([])
  const [ditchStatus, setDitchStatus] = useState([])
  const [visible, setVisible] = useState(false)
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [execid, setExecid] = useState('')
  const [orderForm, setorderForm] = useState('')
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [authList, setAuthList] = useState([])
  const [visibleExecute, setvisibleExecute] = useState(false)
  const [execids, setExecids] = useState('')
  const [visibleSave, setVisibleSave] = useState(false)
  const [statusVal, setStatusVal] = useState('请选择')
  const [flag, setFlag] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  const [filterInfoValue, setFilterInfoValue] = useState('')

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    filter = {
      companyId: ''
    }
    exporfilter = {
      oaId: '',
      ditch: '',
      name: '',
      agreeNo: '',
      applyName: '',
      applyStatus: '',
      executeStatus: '',
      rule: '',
      productsId: '',
      dateMin: '',
      dateMax: '',
      remindMin: '',
      remindMax: ''
    }
    onCommonStaffAlteration()
    api.getPageAuth().then(list => setAuthList(list))
    if (search.id) {
      getEmployeeListExecute()
    } else {
      getEmployeeList()
    }
  }, [])

  const getEmployeeListExecute = () => {
    setLoading(true)
    api.getFortuneSupplyAgreementList({
      limit: pageSize,
      page: currentPage,
      id: search.id
    }).then(data => {
      setList(data.list)
      if(currentPage === 1){
        if(data.ids){
          localStorage.setItem('ids', JSON.stringify(data.ids))
        }else{
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //获取补充协议列表
  const getFortuneSupplyAgreementList = () => {
    setLoading(true)
    api.getFortuneSupplyAgreementList({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId
    }).then(data => {
      setList(data.list)
      if(currentPage === 1){
        if(data.ids){
          localStorage.setItem('ids', JSON.stringify(data.ids))
        }else{
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      setChecked(0)
      setCheckedIdArr([])
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    api.getFortuneSupplyAgreementStatus().then(data => {
      setDitchStatus(data.DitchStatus)
      setExecStatus(data.ExecStatus)
      setSupplyStatus(data.SupplyStatus)
    })
  }

  //筛选时调接口
  const getEmployeeList = () => {
    setLoading(true)
    api.getFortuneSupplyAgreementList({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      ditch: exporfilter.ditch,
      oaId: exporfilter.oaId,
      name: exporfilter.name,
      agreeNo: exporfilter.agreeNo,
      applyName: exporfilter.applyName,
      applyStatus: exporfilter.applyStatus,
      executeStatus: exporfilter.executeStatus,
      rule: exporfilter.rule,
      productsId: exporfilter.productsId,
      date: exporfilter.dateMin == '' ? '' : exporfilter.dateMin + '~' + exporfilter.dateMax,
      remind: exporfilter.remindMin == '' ? '' : exporfilter.remindMin + '~' + exporfilter.remindMax,
    }).then(data => {
      setList(data.list)
      if(currentPage === 1){
        if(data.ids){
          localStorage.setItem('ids', JSON.stringify(data.ids))
        }else{
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        currentPage = 1
        history.replace(match.path)
        exporfilter.oaId = vals.oaId || vals.oaId == 0 ? vals.oaId : ''//eslint-disable-line
        exporfilter.ditch = vals.ditch || vals.ditch == 0 ? vals.ditch : ''//eslint-disable-line
        exporfilter.name = vals.name || vals.name == 0 ? vals.name : ''//eslint-disable-line
        exporfilter.agreeNo = vals.agreeNo || vals.agreeNo == 0 ? vals.agreeNo : ''//eslint-disable-line
        exporfilter.applyName = vals.applyName || vals.applyName == 0 ? vals.applyName : ''//eslint-disable-line
        exporfilter.applyStatus = vals.applyStatus || vals.applyStatus == 0 ? vals.applyStatus : ''//eslint-disable-line
        exporfilter.executeStatus = vals.executeStatus || vals.executeStatus == 0 ? vals.executeStatus : ''//eslint-disable-line
        exporfilter.rule = vals.rule || vals.rule == 0 ? vals.rule : ''//eslint-disable-line
        exporfilter.productsId = vals.productsId || vals.productsId == 0 ? vals.productsId : ''//eslint-disable-line
        exporfilter.dateMin = vals.date || vals.date == 0 ? vals.date[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.dateMax = vals.date || vals.date == 0 ? vals.date[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.remindMin = vals.remind || vals.remind == 0 ? vals.remind[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.remindMax = vals.remind || vals.remind == 0 ? vals.remind[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        if (exporfilter.oaId != '' && String(exporfilter.ditch) == '') {
          return message.warning('请选择ID类型')
        }
        if (exporfilter.oaId == '' && String(exporfilter.ditch) != '') {
          return message.warning('请输入ID')
        }
        getEmployeeList()
        setChecked(0)
        setCheckedIdArr([])
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter = {
      oaId: '',
      ditch: '',
      name: '',
      agreeNo: '',
      applyName: '',
      applyStatus: '',
      executeStatus: '',
      rule: '',
      productsId: '',
      dateMin: '',
      dateMax: '',
      remindMin: '',
      remindMax: ''
    }
    resetFields()
    currentPage = 1
    history.replace(match.path)
    setStatusVal(undefined)
    setStatusValName(undefined)
    setChecked(0)
    setCheckedIdArr([])
    getEmployeeList()
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    if (filter.companyId && filter.companyId.length > 0) {
      if (!Array.isArray(filter.companyId)) {
        filter.companyId = filter.companyId.split(',')
      }
    } else {
      filter.companyId = []
    }
    Object.assign(exporfilter, filter)
    // console.log(exporfilter)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setChecked(selectedRowKeys.length)
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      let arrNew = []
      if (selected) {
        checkedIdArr.push(record.id)
        arrNew = checkedIdArr
      } else {
        arrNew = checkedIdArr.filter(item => item !== record.id)
      }
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
    },
    selectedRowKeys: checkedIdArr
  }

  //筛选
  const onCorpFilter = (data) => {
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getFortuneSupplyAgreementList()
    setChecked(0)
    setCheckedIdArr([])
  }

  const onChangeTable = (pagination) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getEmployeeList()
  }

  //反选
  const onReverse = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    let filterIds = []
    arr.forEach(item => {
      if (!checkedIdArr.includes(item)) {
        filterIds.push(item)
      }
    })
    setCheckedIdArr(filterIds)
    setChecked(filterIds.length)
    selectedIds = filterIds
  }

  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    selectedIds = arr
  }

  const onDelete = (id) => {
    api.getFortuneSupplyAgreementDel({ id: id }).then(() => {
      message.success('删除成功')
      getEmployeeList()
    })
  }

  //补充
  const onReplenish = (value) => {
    setExecid(value.execid)
    api.getFortuneSupplyAgreementOrderInfo({ exId: value.execid }).then(res => {
      const { fileData, orderNo } = res
      setFileList(JSON.parse(fileData).map((item, index) => ({
        ...item,
        uid: index,
        status: 'done'
      })))
      setorderForm(orderNo)
      setVisible(true)
    })
  }

  //补充修改
  const onAlter = (value) => {
    setExecid(value.execid)
    api.getFortuneSupplyAgreementOrderInfo({ exId: value.execid }).then(res => {
      const { fileData, orderNo } = res
      setFileList(JSON.parse(fileData).map((item, index) => ({
        ...item,
        uid: index,
        status: 'done'
      })))
      setorderForm(orderNo)
      setVisible(true)
    })
  }

  //取消
  const onRelevance = () => {
    setVisible(false)
  }

  //补充确定按钮
  const onConfirmReplenish = () => {
    if (orderForm == '') {
      return message.warning('请输入订单编号')
    }
    if (!fileList.length > 0) {
      return message.warning('请上传附件')
    }
    api.getFortuneSupplyAgreementAddOrder({
      exId: execid,
      orderNo: orderForm,
      status: 5,
      fileData: JSON.stringify(fileList)
    }).then(data => {
      setVisible(false)
      getEmployeeList()
      message.success('保存成功')
    }).catch(error => setVisible(false))
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jepg, .PDF, .doc, .docx, .csv, .xlsx, .zip'
	})

  const onTextArea = (e) => {
    setorderForm(e.target.value)
  }

  const onToggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //确认执行
  const handleOkExecute = () => {
    setLoading(true)
    api.getFortuneSupplyAgreementConfirmExec({
      exId: execids,
      is_confirm: 1
    }).then(res => {
      setLoading(false)
      setvisibleExecute(false)
      message.success('确认成功')
      getEmployeeListExecute()
    }).catch(() => setLoading(false))
  }

  //确认执行按钮
  const onExecuteBtn = (id) => {
    setExecids(id)
    setvisibleExecute(true)
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content

    setFieldsValue({
      oaId: filterCont.oaId || filterCont.oaId == 0 ? filterCont.oaId : undefined,//eslint-disable-line
      ditch: filterCont.ditch || filterCont.ditch == 0 ? filterCont.ditch : undefined,//eslint-disable-line
      name: filterCont.name || filterCont.name == 0 ? filterCont.name : undefined,//eslint-disable-line
      agreeNo: filterCont.agreeNo || filterCont.agreeNo == 0 ? filterCont.agreeNo : undefined,//eslint-disable-line
      applyName: filterCont.applyName || filterCont.applyName == 0 ? filterCont.applyName : undefined,//eslint-disable-line
      applyStatus: filterCont.applyStatus || filterCont.applyStatus == 0 ? filterCont.applyStatus : undefined,//eslint-disable-line
      executeStatus: filterCont.executeStatus || filterCont.executeStatus == 0 ? filterCont.executeStatus : undefined,//eslint-disable-line
      rule: filterCont.rule || filterCont.rule == 0 ? filterCont.rule : undefined,
      productsId: filterCont.productsId || filterCont.productsId == 0 ? filterCont.productsId : undefined,
      date: filterCont.date || filterCont.date == 0 ? [moment(filterCont.date[0]), moment(filterCont.date[1])] : undefined,//eslint-disable-line
      remind: filterCont.remind || filterCont.remind == 0 ? [moment(filterCont.remind[0]), moment(filterCont.remind[1])] : undefined //eslint-disable-line
    })//选择保存筛选下拉框中的内容

  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'time') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else if (k === 'month') {
                params[k] = moment(vals[k]).format('YYYY-MM')
              } else {
                params[k] = vals[k]
              }

            }
          }
          setVisibleSave(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisibleSave(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisibleSave(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  return (
    <>
      <CorpFilter onChange={onCorpFilter} />
      <Form onSubmit={formSubmit} {...formItemLayout} className="ant-advanced-search-formTwo csr-management-form" style={{marginTop: 20}}>
        <h4 className="title">其它选项：</h4>
        <div className="search-item-wrap replenish-wrap">
          <div className="search-item"> 
            <Row gutter={24}>
              <Col span={8}>
                <div className="csrManageNo">
                  <FormItem label="ID" labelCol={{ span: 6 }}>
                    {getFieldDecorator('ditch')(<Select placeholder="请选择" dropdownMatchSelectWidth={false} style={{ width: 100 }}>
                      {
                        ditchStatus.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('oaId')(<Input placeholder="请输入" className="csrManageNoOne"/>)}
                  </FormItem>
                </div>
              </Col>
              <Col span={8}>
                <FormItem label="客户名称" labelCol={{ span: 6 }}>
                  {getFieldDecorator('name')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="业务编码" labelCol={{ span: 6 }}>
                  {getFieldDecorator('agreeNo')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24} className={expand ? '' : 'hide'}>
              <Col span={8}>
                <FormItem label="申请人" labelCol={{ span: 6 }}>
                  {getFieldDecorator('applyName')(<Input placeholder="请输入"/>)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="协议状态" labelCol={{ span: 6 }}>
                  {getFieldDecorator('applyStatus')(
                    <Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                      {
                        supplyStatus.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="执行状态" labelCol={{ span: 6 }}>
                  {getFieldDecorator('executeStatus')(
                    <Select placeholder="请选择" dropdownMatchSelectWidth={false}>
                      {
                        execStatus.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="政策" labelCol={{ span: 6 }}>
                  {getFieldDecorator('rule')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="项目" labelCol={{ span: 6 }}>
                  {getFieldDecorator('productsId')(<Input placeholder="请输入" />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="有效时间" labelCol={{ span: 6 }}>
                  {getFieldDecorator('date')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem label="结算提醒日期" labelCol={{ span: 6 }} >
                  {getFieldDecorator('remind')(<RangePicker allowClear={false} />)}
                </FormItem>
              </Col>
            </Row>
          </div>
          <span className="expand-btns" onClick={onToggle}>
            <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
          </span>
        </div>
        <div className="recruit-search-btns">
          <h4 className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>已保存筛选方案：</h4>
					<Select
						placeholder="默认请选择"
						style={{ width: 160 }}
						value={statusVal}
						onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
						onSelect={onChecked}
						className={alterationUnfoldPack ? "staffalteration-unfold" : ""}
            dropdownMatchSelectWidth={false}
					>
			      {filterContent.map((item, index) =>
							<Option value={index} key={index}>
								<div className="closeStyBtn">
									{item.name}
									{flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
										e.stopPropagation()
										onSearchDelete(item.id)
									}} /></span> : null}
								</div>
							</Option>
						)}
					</Select>
					<Button onClick={onSaveFilterTerm} className={alterationUnfoldPack ? "staffalteration-unfold" : ""} style={{ marginLeft: '15px'}}>保存筛选条件</Button>
          <Button onClick={onReset} style={{ marginLeft: '15px'}}>重置</Button>
          <Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">筛选</Button>
        </div>
        <Modal
					title="保存筛选条件"
					visible={visibleSave}
					onOk={HandleOk}
					onCancel={HandleCancel}
				>
					<Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
				</Modal>
      </Form>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="export">
          <Button type="primary" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
        <Auth auths={authList} code="staff-attendance-apply">
          <Link to={`${match.path}/apply`} style={{ cursor: 'pointer' }}>
            <Button type="primary" style={{ marginLeft: 15 }}>申请</Button>
          </Link>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          {checked !== 0 && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
          {checked == 0 //eslint-disable-line
            && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
          <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onAllelection}>全选</span>
        </>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 1800 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="业务编号" dataIndex="agreeNo" fixed="left" width={180} render={(text, record) => (
          <Link to={`/protocol/replenish/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} style={{ cursor: 'pointer' }}>{text}</Link>
        )} />
        <Column title="OAID/代理商ID" dataIndex="oaId" width={150} />
        <Column title="OA客户名称/代理商名称" dataIndex="oaName" width={200} />
        <Column title="客户类型" dataIndex="ditch" />
        <Column title="申请人" dataIndex="applyName" />
        <Column title="申请时间" dataIndex='addTime' />
        <Column title="审批时间" dataIndex="auditTime" />
        <Column title="协议状态" dataIndex="businessName" />
        <Column title="执行状态" dataIndex="executeStatus" />
        <Column title="操作" key="set" fixed="right" width={200} render={(text, record) => (
          <>
            {
              text.delFlag ? <Popconfirm title="请确认是否删除本条记录，删除后不可见？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }}>删除</span>
              </Popconfirm> : null
            }
            {
              text.edit ?
                text.executeStatus == '已补充' ?
                  <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }} onClick={() => onAlter(record)}>修改</span> :
                  text.executeStatus == '已执行' ? <Link to={`${match.path}/execute?id=${record.id}&agreeNo=${record.agreeNo}&contractno=${record.contractno}&execid=${record.execid}`} style={{ cursor: 'pointer' }}>
                    <span style={{ marginRight: 5, color: '#439DDD' }}>修改</span>
                  </Link> : null : null
            }
            {
              text.detail ? <Link to={`${match.path}/examine?id=${record.id}`} style={{ cursor: 'pointer' }}>
                <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }}>查看</span>
              </Link> : null
            }
            {
              text.exec ? <Link to={`${match.path}/execute?id=${record.id}&agreeNo=${record.agreeNo}&contractno=${record.contractno}`} style={{ cursor: 'pointer' }}>
                <span style={{ marginRight: 5, color: '#439DDD' }}>执行</span>
              </Link> : null
            }
            {
              text.confirm ? <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }} onClick={() => onExecuteBtn(record.execid)}>确认</span> : null
            }
            {
              text.add ? <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }} onClick={() => onReplenish(record)}>补充</span> : null
            }
            {
              text.rakeUpFlag ? <Link to={`${match.path}/apply?id=${record.id}&agreeNo=${record.agreeNo}&contractno=${record.contractno}`} style={{ cursor: 'pointer' }}>
                <span style={{ marginRight: 5, color: '#439DDD' }}>编辑</span>
              </Link> : null
            }
          </>
        )} />
      </Table>
      <Modal
        title="信息提示"
        visible={visibleExecute}
        onOk={handleOkExecute}
        onCancel={() => setvisibleExecute(false)}
      >
        <div style={{height: '150px', textAlign: 'center'}}>确认该执行情况请点“确定”按钮</div>
      </Modal>
      <Drawer
        title="补充订单信息"
        width={800}
        onClose={() => setVisible(false)}
        visible={visible}
        destroyOnClose={true}
      >
        <div className="spin">
          <div className="drawer">
            <div><span style={{ color: 'red', marginRight: '4px' }}>*</span>订单编号：</div>
            <div>
              <Input.TextArea placeholder="请输入订单编号" maxLength={100} style={{ width: 500, marginLeft: '70px' }} onChange={onTextArea} value={orderForm} />
            </div>
          </div>
          <div style={{ marginTop: '40px' }}>
            <div style={{ paddingLeft: '30px' }}><span style={{ color: 'red', marginRight: '4px' }}>*</span>附件：</div>
            <div className="dragger-box" ref={draggerRef} style={{ width: 500, marginLeft: '70px' }}>
                    <Dragger
                      {...uploadFiles}
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                      <p className="ant-upload-hint">
                      支持扩展名：GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip 单个文件大小不超过5M，不超过5个附件。
    </p>
                    </Dragger>
                  </div>
          </div>
          <div className="replenish-button" >
            <BtnGroup onCancel={onRelevance} onConfirm={onConfirmReplenish} />
          </div>
        </div>
      </Drawer>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getFortuneSupplyAgreementChangeExportTpl"
        fieldsUrl="getFortuneSupplyAgreementChangeTplItems"
        saveUrl="saveFortuneSupplyAgreementChangeExportTpls"
        exportUrl="exportFortuneSupplyAgreementChange"
        method="export"
        parame={{ ExportType: 'supplyagreement.export', id: selectedIds, ...exporfilter }}
        type={1}
        cRef={productsRef}
      />
    </>
  )
}

export default Form.create()(Replenish)