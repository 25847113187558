/**
 * 模块名称: 发票详情
 * @author lids@372163.com
 */
import React, { useEffect, useState, useRef } from 'react'
import { parseSearch, debounce } from '@/utils'
import { Button, Form, Icon, Input, message, Modal, Select, Spin, Upload } from 'antd'
import PublicDetail from './PublicDetail'
import { Link } from "react-router-dom";
import api from "../../../api";
import { useSelector } from "react-redux"
import CryptoJS from 'crypto-js'
import { upLoadModule } from '@/utils/common'

const FormItem = Form.Item
const Option = Select.Option
let areaTxt = '', result = '', subParams = ''

const Detail = (props) => {
  const { location, history } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const [detail, setDetail] = useState({})
  const [params, setParams] = useState([])
  const search = parseSearch(location.search)
  const userInfo = useSelector(state => state.userInfo)

  const [loading, setLoading] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')

  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填

  const [receiveIds, setReceiveIds] = useState(undefined)
  //领取人id
  const [receiveId, setReceiveId] = useState(undefined)
  //领取人名字
  const [receiveName, setReceiveName] = useState(undefined)
  //领取人列表
  const [receiveList, setReceiveList] = useState([])

  const [showModalForHexiao, setShowModalForHexiao] = useState(false)
  const [fileList, setFileList] = useState([])
  const draggerRef = useRef(null)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext && plaintextTwo && +search.time) {
      if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
        setParams(parseSearch(location.search));
      } else {
        message.error('没有本页访问权限')
        setTimeout(() => {
          history.go(-2)
        }, 1000)
      }
    }
  }, [])

  const geReceiveList = (vals) => {
    setFieldsValue({ receiveId: '' })
    let val = vals.trim()
    if (val) {
      return api.invoiceReceive({ name: val }).then(res => {
        setReceiveList(res)
      })
    } else {
      return (new Promise(function () {
        setReceiveList([])
      }))
    }
  }

  /**
   * 选择转出人操作
   * @param option
   */
  const changeReceiveList = (option, value) => {
    // console.log(option, value)
    setReceiveIds(value)
    setReceiveId(option.props['data-value'])
    setReceiveName(option.props.children)
  }

  //设置发票详情数据
  const getDetail = (detail) => {
    setDetail(detail);
    if (search.msgId) {
      api.setMessageReaded({ ids: [search.msgId] })
    }
    if (detail.receiverFlag) {
      geReceiveList(detail.receiverName ? detail.receiverName : detail.applyName).then(function () {
        setFieldsValue({
          receiveId: detail.receiverId ? detail.receiverId : detail.applyId,
        })
      })
    }

    //初始化领取人信息
    setReceiveId(JSON.stringify({
      "id": detail.receiverId ? detail.receiverId : detail.applyId,
      "staffName": detail.receiverName ? detail.receiverName : detail.applyName,
    }))

    if (JSON.stringify(detail) !== "[]") {
      let data = Object.assign({}, detail)
      if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
        setAuditAuth(true)
        setHasChargerPower(data.auditAuth.hasChargerPower)
        setReplenish(data.auditAuth.replenish)
        setReturnUp(data.auditAuth.return_up)
        setRejectMsgRequire(data.auditAuth.reject_msg_require)
      } else {
        setAuditAuth(false)
        setHasChargerPower(false)
        setReplenish(false)
        setReturnUp(false)
        setRejectMsgRequire(false)
      }
    }
    setLoading(false)
  }

  //跳转到列表页
  const onCancel = () => {
    history.push({
      pathname: '/protocol/invoice'
    })
  }

  //获取领取人信息
  function getReceiveData() {
    let receiveData = JSON.parse(receiveId);

    let id = '';
    let name = '';

    if (receiveData != undefined) {
      id = receiveData.id;
      name = receiveData.staffName;
    }
    return { id, name };
  }

  //点击4个审批的按钮
  const audit = (no) => {
    areaTxt = ''
    result = no

    if (no == 1) {
      setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    } else if (no == 2 || no == 3) {
      setLoading(true)
      api.invoiceAudit({
        id: detail.id,
        type: result,
        remark: areaTxt
      }).then(res => {
        //转上级或者补充资料 返回列表页
        let timer = setTimeout(() => {
          onCancel()
          clearTimeout(timer)
        }, 1000)
      })
    } else {
      validateFields((err, vals) => {
        if (!err) {
          subParams = Object.assign({}, vals)
          if (no == 0) {//eslint-disable-line
            setResPlaceholder('请输入审批意见')
            setModalVisible(true)
          }
        }
      })
    }

  }

  const invNumber = (val) => {
    validateFields((err, vals) => {
      if (!err) {
        let subParams = Object.assign({}, vals)
        let uploadList = []
        for (let i = 0, k = fileList.length; i < k; i++) {
          if (fileList[i].status !== 'removed') {
            uploadList.push({
              url: fileList[i].url,
              name: fileList[i].name
            })
          }
        }
        // if (val === 1 && uploadList.length === 0) {
        //     return message.error("请上传附件")
        // }
        let param = {
          id: detail.id,
          invoiceNumber: subParams.invoiceNumber,
          invoiceUrl: uploadList
        }

        if (val === 1) {
          let receiveData = getReceiveData()
          param.invoiceReceiveId = receiveData.id
          param.invoiceReceiveName = receiveData.name
        }

        setLoading(true)
        api.invoiceNumber(param)
          .then(res => {
            message.success('操作成功', function () {
              window.location.reload()
            })
          })
          .catch(error => {
            setLoading(false);
          })
      }
    })
  }

  const handleOk = () => {
    if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
      message.warning('请输入至少1个字符')
      return
    }

    let param = {
      id: detail.id,
      type: result,
      remark: areaTxt,
    }

    //仅通过允许填写
    if (result === 0) {
      let receiveData = getReceiveData();
      param.invoiceReceiveId = receiveData.id;
      param.invoiceReceiveName = receiveData.name;
    }

    setModalVisible(false)
    setLoading(true)
    // console.log(param)
    api.invoiceAudit(param).then(res => {
      message.success('审批成功', function () {
        window.location.reload()
      })
    }).catch(error => {
      setLoading(false);
    })

  }

  //取消弹窗
  const handleCancel = () => {
    setModalVisible(false)
  }

  //同意的样式
  const agreeNode = () => {
    return result === 0 || result === 4 ? <>
      同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </> : <>
      不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
    </>
  }

  //审批意见
  const getAreaTxt = (e) => {
    areaTxt = e.target.value.trim()
  }

  //确定核销
  const verification = () => {
    if (areaTxt.length < 1) {//eslint-disable-line
      message.warning('请输入至少1个字符')
      return
    }
    api.returnInvoiceCancellation({ id: detail.id }).then(res => {
      message.success('核销成功', function () {
        window.location.reload()
      })
    })
  }

  const showModal = () => {
    setShowModalForHexiao(true)
  };

  //取消弹窗
  const handleCancelForHexiao = () => {
    setShowModalForHexiao(false)
  }

  //修改领取人
  const onReceiver = () => {
    if (!receiveIds && !receiveName) {
      return message.warning('请选择新的领取人')
    }
    api.setInvoiceAddReciverInfo({
      id: detail.id,
      reciver: receiveName,
      reciverId: receiveIds
    }).then(res => {
      message.success('修改成功', function () {
        window.location.reload()
      })
    }).catch(err => console.log(err))
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.ofd, .gif, .png, .jpg, .PDF, .doc, .docx, .csv, .xlsx, .zip, .rar, .jepg',
    allowSizeType: 2
  })

  return (
    <Spin spinning={loading}>
      <div className="add-newinvoice-detail">
        <PublicDetail params={params} type={'detail'} getDetail={getDetail} form={props.form} />
        <div>
          <div className="blank-line"></div>
          {detail.invNumberFlag && hasChargerPower &&
            <FormItem label="发票号">
              {getFieldDecorator('invoiceNumber', {
                rules: [{ required: true, message: '请输入发票号' }]
              })
                (<Input placeholder="请输入发票号" style={{ width: 180 }} />)}
            </FormItem>
          }
          {detail.invAnnexFlag &&
            <FormItem label="上传附件" style={{ marginLeft: 40 }}>
              {getFieldDecorator('dragger', { rules: [{ required: false, message: '请上传附件' }] })(
                <div className="dragger-box" ref={draggerRef}>
                  <div style={{ display: 'flex' }}>
                    <Upload {...uploadFiles}>
                      <Button>
                        <Icon type="upload" /> 上传文件
                      </Button>
                    </Upload>
                    <div title="支持扩展名：OFD、GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip、JEPG 单个文件大小不超过5M，不超过5个附件。"
                      className="upload-hint">支持扩展名：OFD、GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip、JEPG 单个文件大小不超过5M，不超过5个附件。</div>
                  </div>
                </div>
              )}
            </FormItem>
          }
        </div>
        {detail.receiverFlag && detail.invTypeName !== "电子专票" && detail.invTypeName !== "电子普票" && <FormItem label="领取人">
          {getFieldDecorator('receiveId', {
            initialValue: receiveId,
            rules: [{ required: result == 1 ? false : true, message: '请输入领取人' }]
          })(
            <Select
              showSearch
              showArrow
              placeholder="请输入领取人"
              onSearch={geReceiveList}
              // onChange={handleChangeTransferInUserList}
              onChange={(value, option) => changeReceiveList(option, value)}
              notFoundContent='未搜索到相关领取人'
              filterOption={false}
              style={{ width: 180 }}
            >
              {receiveList.map(item => <Option key={item.id} value={item.id} data-value={JSON.stringify(item)}>{item.staffName} {item.staffNo}</Option>)}
            </Select>
          )}
          {
            detail.auditStatusName == '审批通过' &&
            <Button style={{ marginLeft: '20px' }} type="primary" size="small" onClick={onReceiver}>保存</Button>
          }
        </FormItem>}
        {/*<div className="blank-line"></div>*/}
        <div className="task-bottom-btns">
          {/* <Button onClick={() => leaveOutAudit(0)} type="primary">通过</Button>*/}
          {
            detail.auditStatusName == '已驳回' && search.msgId ?
              <Link to={`/protocol/invoice/apply?id=${search.id}&msgId=${search.msgId}`}>
                <Button type="primary">驳回重提</Button>
              </Link> : null
          }
          {detail.showHandle && <>
            {detail.redFlag &&
              <Button className="operate-icon" type="dashed" size="default">
                <Link to={`/protocol/invoice/modify${location.search}`}>冲红</Link>
              </Button>
            }
            {
              detail.colleStatusName === '未收款' &&
              +detail.clearedMoney !== 0 &&
              userInfo.id == detail.leadCadreId &&
              <Button className="operate-icon" type="primary" size="default" onClick={() => {
                history.push('/protocol/invoice/clearDetail', {
                  unInvoiceData: detail,
                  creatorId: detail.leadCadreId,
                  type: detail.clientsouce
                })
              }}>发票清理</Button>
            }
            {
              detail.refundFag ? <Button className="operate-icon" type="primary" size="default">
                <Link to={`/protocol/invoice/rejectInvoice${location.search}`}>退票</Link>
              </Button> : null
            }
          </>
          }
          {
            detail.invCancel &&
            <Button type="primary" style={{ marginLeft: '20px' }} onClick={showModal}>核销</Button>
          }
        </div>
        <Modal
          title={"核销"}
          visible={showModalForHexiao}
          onOk={debounce(() => { verification() }, 3000)}
          onCancel={handleCancelForHexiao}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={handleCancelForHexiao}>取消</Button>,
            <Button key="submit" type="primary" onClick={debounce(() => { verification() }, 3000)}>确定</Button>
          ]}
        >
          <div className="officework-modal-content">
            <span className="label" style={{ width: "20%" }}>审批意见：</span>
            <Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "80%" }} maxLength={100} />
          </div>
        </Modal>
        {
          auditAuth && <div className="btns">
            {replenish && <Button onClick={debounce(() => { audit(3) }, 3000)} className="orange-btn">补充资料</Button>}
            {returnUp && <Button onClick={debounce(() => { audit(2) }, 3000)} className="orange-btn">转上级</Button>}
            {hasChargerPower && <Button onClick={() => audit(1)}>驳回</Button>}
            {hasChargerPower &&
              (
                detail.invTypeName == "电子专票" || detail.invTypeName == "电子普票" ?
                  (<Button onClick={detail.invAnnexFlag ? () => invNumber(1) : () => audit(0)} type="primary">{detail.invAnnexFlag ? '已领取' : '通过'}</Button>) :
                  (detail.invNumberFlag ? <Button onClick={() => invNumber()} type="primary">待领取</Button>
                    : <Button onClick={() => audit(0)} type="primary">{detail.receiverFlag ? '已领取' : '通过'}</Button>)
              )
              // (detail.invNumberFlag ? <Button onClick={() => invNumber()} type="primary">待领取</Button>
              //   : <Button onClick={() => audit(0)} type="primary">{detail.receiverFlag ? '已领取' : '通过'}</Button>)
            }
          </div>
        }
        <Modal
          title={agreeNode()}
          visible={modalVisible}
          onOk={debounce(() => { handleOk() }, 3000)}
          onCancel={handleCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={handleCancel}>取消</Button>,
            <Button key="submit" type="primary" onClick={debounce(() => { handleOk() }, 3000)}>确定</Button>
          ]}
        >
          <div className="officework-modal-content">
            <span className="label">意见：</span>
            <Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} />
          </div>
        </Modal>
      </div>
    </Spin>
  )
}
export default Form.create()(Detail)