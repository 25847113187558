/**
 * 模块名称: 申请清理-未收款发票列表
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import { parseSearch } from '@/utils'
import {
  Table,
	Descriptions,
  Button,
  Radio
} from 'antd'

const { Column } = Table
let pageSize = 10
let currentPage = 1

const UninvoiceList = (props) => {
  const { location, history, match } = props
  const [propsData, setPropsData] = useState({})
  const [fortuneUnInvoiceList, setFortuneUnInvoiceList] = useState([])
  const [radioId, setRadioId] = useState('')
  const [unInvoiceData, setUnInvoiceData] = useState({})
  const [count, setCount] = useState(0)

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    getFortuneUnInvoice()
    // console.log('location', location)
    if(!location.state.venderData){
      history.replace('/protocol/invoice')
      return
    }
    setPropsData(location.state.venderData)
  }, [])

  //获取未收款发票列表
  const getFortuneUnInvoice = () => {
    let OAID 
    if (location.state.venderData.type == 1) {
      OAID = location.state.venderData.agentId
    } else {
      OAID = location.state.venderData.clientId
    }
    api.getFortuneUnInvoice({
      OAID: OAID,
      type: location.state.venderData.type,
      is_leadCadre: 1
      // limit: pageSize,
      // page: currentPage,
    }).then(data => {
      setFortuneUnInvoiceList(data.list)
      setCount(data.count)
    })
  }

  const getUnInvoiceId = (e) => {
    setRadioId(e.target.value)
    for(let i=0; i<fortuneUnInvoiceList.length; i++){
      if(fortuneUnInvoiceList[i].id === e.target.value){
        setUnInvoiceData(fortuneUnInvoiceList[i])
      }
    }
  }

  const goDetail = () => {
    // console.log(unInvoiceData, location)
    history.push('/protocol/invoice/clearDetail', { 
      unInvoiceData,
      creatorId: location.state.venderData.creatorId,
      type: location.state.venderData.type
    })
  }

  //点击分页
  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getFortuneUnInvoice()
  }

  return (
    <div className="add-newinvoice-detail">
      <div className="ask-info">
        <div className="record-list-title">客户信息</div>
        <Descriptions layout="vertical" column={4} className="pro-detail-title" style={{borderBottom: 'none'}}>
          <dItem label="OA客户名称">{propsData.client}</dItem>
          <dItem label="OAID">{propsData.clientId}</dItem>
        </Descriptions>
      </div>
      <div className="blank-line"></div>
      <div className="ask-info">
        <div className="record-list-title">发票信息</div>
        <Table
          dataSource={fortuneUnInvoiceList}
          rowKey="id"  
          className="fortuneUnInvoiceList-info"
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="业务编号" dataIndex="invoiceNo" />
          <Column title="OA客户名称" dataIndex="customerName" />
          <Column title="发票金额" dataIndex="money" />
          <Column title="待清理金额" dataIndex="clearedMoney" />
          <Column title="发票类型" dataIndex="invtype" />
          <Column title="发票号" dataIndex="invoiceNumber" />
          <Column title="发票状态" dataIndex="stcodetag" />
          <Column title="收款状态" dataIndex="collestatusName" />
          <Column title="领取时间" dataIndex="collectionDate" />
          <Column title="负责人" dataIndex="leadCadreName" />
          <Column title="" render={(text, record) => {
            return <Radio value={text.id} onChange={getUnInvoiceId} checked={radioId == text.id} />
          }}/>
        </Table>
      </div>
      <div className="task-bottom-btns" style={{position: 'absolute', bottom: '-20px', right: '0px', width: '100%', paddingBottom: '10px'}}>
        <div className="blank-line" style={{height: '10px', marginLeft: '-40px', marginBottom: '10px'}}></div>
        <Button className="operate-icon" size="default" onClick={() => {
          history.replace('/protocol/invoice')
        }}>取消</Button>
        <Button disabled={JSON.stringify(unInvoiceData) === '{}'} className="operate-icon" type="primary" size="default" onClick={goDetail}>下一步</Button>
      </div>
    </div>
  )
}
export default UninvoiceList